import { About } from '@webapp/landing/src/components/ru/about';
import { Safety } from '@webapp/landing/src/components/ru/advantages/safety';
import { Contacts } from '@webapp/landing/src/components/ru/contacts';
import { Agreement } from '@webapp/landing/src/components/ru/docs/agreement';
import { Confidential } from '@webapp/landing/src/components/ru/docs/confidential';
import { DocsCookie } from '@webapp/landing/src/components/ru/docs/docs-cookie';
import { PdPolicy } from '@webapp/landing/src/components/ru/docs/pd-policy';
import { Functions } from '@webapp/landing/src/components/ru/functions';
import { API } from '@webapp/landing/src/components/ru/opportunities/api';
import { Email } from '@webapp/landing/src/components/ru/opportunities/email';
import { HR } from '@webapp/landing/src/components/ru/opportunities/hr';
import { Marketing } from '@webapp/landing/src/components/ru/opportunities/marketing';
import { Tests } from '@webapp/landing/src/components/ru/opportunities/tests';
import { Votes } from '@webapp/landing/src/components/ru/opportunities/votes';
import { PaymentVariants } from '@webapp/landing/src/components/ru/pricing/payment-variants';
import { PricingPlans } from '@webapp/landing/src/components/ru/pricing/pricing-plans';
import { Templates } from '@webapp/landing/src/components/ru/templates';

import { ConnectionSteps } from 'components/ru/connection-steps';
import { CustomCss } from 'components/ru/docs/custom-css';
import { HookSchema } from 'components/ru/docs/hook-schema';
import { License } from 'components/ru/docs/license';
import { PublicApi } from 'components/ru/docs/public-api';
import { PublicOffer } from 'components/ru/docs/public-offer';

type Route = {
    path: string;
    title: string;
    children?: Array<Route>;
    component?: any;
};

// TODO purge
export const LandingRoutes: Record<string, Route> = {
    konstruktorForm: {
        path: 'konstruktor-form',
        title: 'Конструктор форм'
    },
    provedenie: {
        path: 'provedenie',
        title: 'Проведение опросов',
        children: [
            {
                path: 'kak-provesti-opros',
                title: 'Как провести допрос?'
            }
        ]
    },
    indeksLoyalnostiNps: {
        path: 'indeks-loyalnosti-nps',
        title: 'Индекс лояльности клиентов NPS'
    }
};

export const StaticRoutes = {
    about: {
        title: 'О сервисе',
        path: 'about'
    },
    blog: {
        title: 'Блог',
        path: 'blog'
    },
    contacts: {
        title: 'Контакты',
        path: 'contacts'
    },
    capabilitiesApi: {
        title: 'API',
        path: 'capabilities-api'
    },
    capabilitiesHr: {
        title: 'Возможности',
        path: 'capabilities-hr'
    },
    capabilitiesMarketing: {
        title: 'Для маркетинга',
        path: 'capabilities-marketing'
    },
    capabilitiesMailing: {
        title: 'E-mail рассылка',
        path: 'capabilities-mailing'
    },
    capabilitiesTests: {
        title: 'Тесты',
        path: 'capabilities-tests'
    },
    capabilitiesVotes: {
        title: 'Голосования',
        path: 'capabilities-votes'
    },
    advantagesSafety: {
        title: 'Преимущества',
        path: 'advantages-safety'
    },
    pricingPlans: {
        title: 'Тарифы',
        path: 'pricing-plans'
    },
    functions: {
        title: 'Функции',
        path: 'functions'
    },
    docsPublicOffer: {
        title: 'Оферта',
        path: 'public-offer'
    },
    docsEula: {
        title: 'Пользовательское соглашение (от 13.10.2021)',
        path: 'docs-eula'
    },
    docsLicence: {
        title: 'Шаблон договора',
        path: 'docs-licence'
    },
    docsPrivacyPolicy: {
        title: 'Политика конфиденциальности (актуальность 10.2021)',
        path: 'docs-privacy-policy'
    },
    docsCustomCss: {
        title: 'Пользовательские стили для опросов',
        path: 'docs-custom-css'
    },
    docsPrivateApi: {
        title: 'Публичное апи',
        path: 'docs-private-api'
    },
    docsWebHooks: {
        title: 'Веб-хуки',
        path: 'docs-web-hooks'
    },
    pdPolicy: {
        title: 'Политика ПДн',
        path: 'pd-policy'
    },
    docsCookie: {
        title: 'Политика cookie',
        path: 'docs-cookie'
    },
    pricingConnectionSteps: {
        title: 'Порядок подключения',
        path: 'pricing-connection-steps'
    },
    pricingPaymentVariants: {
        title: 'Способы оплаты',
        path: 'pricing-payment-variants'
    },
    templates: {
        title: 'Шаблоны опросов',
        path: 'templates'
    }
};

export const StaticRoutesFlat = {
    [StaticRoutes.about.path]: {
        title: StaticRoutes.about.title,
        component: About
    },
    [StaticRoutes.contacts.path]: {
        title: StaticRoutes.contacts.title,
        component: Contacts
    },
    [StaticRoutes.capabilitiesApi.path]: {
        title: StaticRoutes.capabilitiesApi.title,
        component: API
    },
    [StaticRoutes.capabilitiesHr.path]: {
        title: StaticRoutes.capabilitiesHr.title,
        component: HR
    },
    [StaticRoutes.capabilitiesMarketing.path]: {
        title: StaticRoutes.capabilitiesMarketing.title,
        component: Marketing
    },
    [StaticRoutes.capabilitiesMailing.path]: {
        title: StaticRoutes.capabilitiesMailing.title,
        component: Email
    },
    [StaticRoutes.capabilitiesTests.path]: {
        title: StaticRoutes.capabilitiesTests.title,
        component: Tests
    },
    [StaticRoutes.capabilitiesVotes.path]: {
        title: StaticRoutes.capabilitiesVotes.title,
        component: Votes
    },
    [StaticRoutes.advantagesSafety.path]: {
        title: StaticRoutes.advantagesSafety.title,
        component: Safety
    },
    [StaticRoutes.pricingPlans.path]: {
        title: StaticRoutes.pricingPlans.title,
        component: PricingPlans
    },
    [StaticRoutes.functions.path]: {
        title: StaticRoutes.functions.title,
        component: Functions
    },
    [StaticRoutes.docsPublicOffer.path]: {
        title: StaticRoutes.docsPublicOffer.title,
        component: PublicOffer
    },
    [StaticRoutes.docsEula.path]: {
        title: StaticRoutes.docsEula.title,
        component: Agreement
    },
    [StaticRoutes.docsLicence.path]: {
        title: StaticRoutes.docsLicence.title,
        component: License
    },
    [StaticRoutes.docsPrivacyPolicy.path]: {
        title: StaticRoutes.docsPrivacyPolicy.title,
        component: Confidential
    },
    [StaticRoutes.docsCustomCss.path]: {
        title: StaticRoutes.docsCustomCss.title,
        component: CustomCss
    },
    [StaticRoutes.docsPrivateApi.path]: {
        title: StaticRoutes.docsPrivateApi.title,
        component: PublicApi
    },
    [StaticRoutes.docsWebHooks.path]: {
        title: StaticRoutes.docsWebHooks.title,
        component: HookSchema
    },
    [StaticRoutes.pdPolicy.path]: {
        title: StaticRoutes.pdPolicy.title,
        component: PdPolicy
    },
    [StaticRoutes.docsCookie.path]: {
        title: StaticRoutes.docsCookie.title,
        component: DocsCookie
    },
    [StaticRoutes.pricingConnectionSteps.path]: {
        title: StaticRoutes.pricingConnectionSteps.title,
        component: ConnectionSteps
    },
    [StaticRoutes.pricingPaymentVariants.path]: {
        title: StaticRoutes.pricingPaymentVariants.title,
        component: PaymentVariants
    },
    [StaticRoutes.templates.path]: {
        title: StaticRoutes.templates.title,
        component: Templates
    }
};
