export const confidentialHTML = `<div class="docHTML">
<p>Настоящая Политика конфиденциальности сайта https://testograf.ru (далее &ndash; Сайт) разработана в соответствии с требованиями законодательства Российской Федерации (далее - РФ).</p>
<p>ООО &laquo;Тестограф&raquo; (далее &ndash; Администрация сайта) является владельцем Сайта и, в связи с использованием Вами (в качестве Пользователя или Представителя Пользователя) Сайта осуществляет обработку Ваших персональных данных, а также персональных данных Респондентов, участвующих в проводимых Вами опросах (далее &ndash; Персональные данные, ПДн).</p>
<p>Политика конфиденциальности Сайта (далее &ndash; Политика конфиденциальности, Политика) соответствует (не противоречит) политике Администрации сайта в отношении обработки персональных данных, размещенной на Сайте по адресу <a href="https://www.testograf.ru/ru/pd-policy" target="_blank">https://testograf.ru/ru/pd-policy</a>.</p>
<p>Политика конфиденциальности Сайта определяет то, как Администрация сайта может обрабатывать Персональные данные Пользователей (Представителей Пользователей) и Респондентов (далее &ndash; Субъектов ПДн), а именно:</p>
    <p> - Цели обработки Персональных данных;</p>
    <p> - Атрибутный состав (категории и перечень) обрабатываемых Персональных данных для каждой цели обработки;</p>
    <p> - Основания обработки Персональных данных;</p>
    <p> - Порядок (способы) обработки Персональных данных при использовании Сайта;</p>
    <p> - Сроки обработки (включая хранение) Персональных данных;</p>
    <p> - Порядок удаления Персональных данных при достижении целей их обработки или при наступлении иных законных оснований;</p>
    <p> - Меры Администрации сайта по обеспечению безопасности Персональных данных, с целью защиты прав и свобод Субъектов ПДн при обработке их Персональных данных;</p>
    <p> - Права Субъектов ПДн в отношении обработки их Персональных данных и соответствующие обязанности Администрации сайта.</p>
<p style="text-align: center"><strong>1. Термины и определения, используемые в Политике</strong></p>
<p><strong>1.1.</strong> В Политике конфиденциальности используются следующие термины, имеющие указанные ниже значения:</p>
<p><strong>1.1.1.</strong> <strong>Администрация сайта</strong> - ООО &laquo;Тестограф&raquo; (ИНН 5003118105; ОГРН 1165003051201; местонахождение: Россия, &nbsp; &nbsp; 142034, Московская Область, г. Видное, п Мещерино, мкр Южные Горки, д. 45), которое:</p>
    <p> - осуществляет управление Сайтом и обладает всеми необходимыми правами в отношении Сайта;</p>
    <p> - является обладателем исключительного права на программу для ЭВМ &laquo;testograf 2.0&raquo; (свидетельство №2019618230).</p>
    <p> - является оператором персональных данных (рег. номер в реестре операторов персональных данных, опубликованный по адресу https://pd.rkn.gov.ru/operators-registry/operators-pst/: 77-17-007222).</p>
<p><strong>1.1.2.</strong> <strong>Сайт</strong> &ndash; web-сайт, размещенный по адресу <a href="https://www.testograf.ru" target="_blank">https://www.testograf.ru</a>, представляющий собой программные и (или) аппаратные средства, позволяющие Пользователям (Представителям Пользователей) совершать действия по созданию и проведению опросов, включая использование отдельных функций, список которых приведен по адресу <a href="https://www.testograf.ru/ru/functions" target="_blank">https://www.testograf.ru/ru/functions</a> и его функциональность, описанную в Пользовательском соглашении и технически реализованную на Сайте.</p>
<p>Под Сайтом в зависимости от контекста понимаются, включая, но не ограничиваясь: программное обеспечение Сайта, отдельные функции, его внешний вид, наполнение страниц на Сайте, базы данных.</p>
<p><strong>1.1.3.</strong> <strong>Конфиденциальность Персональных данных</strong> - обязательное для соблюдения Администрацией сайта, Пользователями (Представителями Пользователей) или иным получившим доступ к Персональным данным лицом требование не допускать их раскрытия или распространения без согласия Субъектов ПДн или наличия иного законного основания.</p>
<p><strong>1.1.4.</strong> <strong>Обработка Персональных данных</strong> - любое действие (операция) или совокупность действий (операций), совершаемых Администрацией сайта в отношении Персональных данных Субъектов ПДн с использованием средств автоматизации или без использования таких средств, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение ПДн.</p>
<p>Конкретные способы обработки и действия, совершаемые с Персональными данными приведены по тексту Политики конфиденциальности.</p>
<p><strong>1.1.5.</strong> <strong>Оператор персональных данных (Оператор)</strong> - юридическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку Персональных данных, а также определяющие цели обработки Персональных данных, состав Персональных данных, подлежащих обработке, действия (операции), совершаемые с Персональными данными.</p>
<p>Оператором персональных данных в отношении данных Пользователей (Представителей Пользователей) является Администрация сайта.</p>
<p>Оператором персональных данных Респондентов являются Пользователи Сайта, осуществляющие создание и проведение опросов Респондентов посредством функционала Сайта.</p>
<p><strong>1.1.6.</strong> <strong>Персональные данные</strong> - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (Субъекту ПДн).</p>
<p>Субъектами Персональных данных являетесь Вы (Пользователь или Представитель Пользователя), а также Респонденты.</p>
<p>Конкретный перечень обрабатываемых Персональных данных приведен по тексту Политики конфиденциальности.</p>
<p><strong>1.1.7. Пользователь</strong> &ndash; контрагент/лицо, которое является стороной Пользовательского соглашения и/или Лицензионного договора. Пользователь может совершать юридически значимые и фактические действия через своих Представителей. Пользователем может стать только лицо, предварительно заключившее с Администрацией сайта Лицензионный договор и/или Пользовательское соглашение.</p>
<p><strong>1.1.8.</strong> <strong>Представитель Пользователя</strong> - физическое лицо (в том числе, работник Пользователя), обладающее на законных основаниях правом действовать от имени и в интересах Пользователя (на основании доверенности или без таковой) и прошедшее Авторизацию и Аутентификацию на Сайте. Представитель Пользователя является субъектом Персональных данных.</p>
<p><strong>1.1.9.</strong> <strong>Респондент</strong> &ndash; физическое лицо, участвующее в опросах, созданных и проводимых Пользователем посредством функционала Сайта.</p>
<p><strong>1.1.10.</strong> <strong>Пользовательское соглашение Сайта (далее - Соглашение)</strong> &ndash; Соглашение, устанавливающее права и обязанности Администрации сайта и Пользователя, связанные с предоставлением и осуществлением доступа к использованию функционала Сайта. Соглашение доступно по следующему адресу <a href="https://www.testograf.ru/ru/docs-eula" target="_blank">https://www.testograf.ru/ru/docs-eula</a>.</p>
<p><strong>1.1.11.</strong> <strong>Авторизация</strong> - процедура предоставления Пользователю (Представителю Пользователя) доступа в личный кабинет Сайта.</p>
<p><strong>1.1.12.</strong> <strong>Аутентификация</strong> - процедура проверки подлинности предъявленного идентификатора (логина) Пользователя (Представителя Пользователя) в соответствии с Соглашением.</p>
<p><strong>1.2.</strong> Любые иные термины и определения, использующиеся в Политике конфиденциальности и не упомянутые в настоящем разделе, будут толковаться системно, в связи с другими терминами, отдельными условиями, а также в соответствии со смыслом, вытекающим из текста Политика конфиденциальности.</p>
<p><strong>1.3.</strong> В случае расхождения, приведенного выше понятия с понятием, определение которого содержится в законодательстве РФ, применяется соответствующее понятие и его определение, данное в соответствии с законодательством РФ, если иное не предусмотрено таким законодательством.</p>
<p style="text-align: center"><strong>2.</strong> <strong>Общие положения</strong></p>
<p><strong>2.1.</strong> Использование Сайта Пользователем (Представителем Пользователя) подтверждает факт его ознакомления с Политикой конфиденциальности.</p>
<p><strong>2.2.</strong> В случае несогласия с условиями Политики конфиденциальности Пользователь (Представитель Пользователя) должен прекратить использование Сайта.</p>
<p><strong>2.3.</strong> Политика конфиденциальности применяется только к обработке Персональных данных, осуществляемой Администрацией сайта в связи с предоставлением Пользователю (Представителю Пользователя) функционала Сайта на основании заключенного Соглашения. Администрация сайта не контролирует информационные ресурсы третьих лиц, на которые Пользователь (Представитель Пользователя) может перейти посредством использования Сайта.</p>
<p><strong>2.4.</strong> Администрация сайта не проверяет и не гарантирует достоверность Персональных данных, предоставляемых Пользователем (Представителем Пользователя) или Респондентами.</p>
<p style="text-align: center"><strong>3.</strong> <strong>Обрабатываемые данные, цели их обработки</strong></p>
<p><strong>3.1.</strong> Администрация сайта в качестве Оператора обрабатывает следующие персональные данные Пользователя (Представителя Пользователя), полученные от Пользователя (при регистрации на Сайте или заключении Лицензионного договора), а также образовавшаяся/собранная в ходе использования Сайта: фамилия, имя, отчество; адрес электронной почты; номер телефона; адрес; данные о действиях Пользователя (Представителей Пользователя) на Сайте (в т.ч. данные собираемые посредством встроенных на Сайте метрических программ); данные об устройстве, которое используется Пользователем (Представителем Пользователя) при посещении Сайта (в том числе модель и версия операционной системы, уникальные идентификаторы устройства, данные о мобильной сети, IP-адрес, данные о территориальном месте нахождения); данные о дате, времени и сумме оплаты за услуги Администрации Сайта; а также иные данные, предоставляемые Пользователем (Представителем Пользователя) в ходе использования Сайта.</p>
<p><strong>3.2.</strong> Указанные в п. 3.1 Политики конфиденциальности Персональные данные Пользователя (Представителя Пользователя) обрабатываются Администрацией сайта с целью предоставления Пользователю (Представителю Пользователя) возможности использования Сайта в рамках выполнении Администрацией сайта своих обязательств или реализации своих прав в рамках Соглашения, включая: предоставление доступа в личный кабинет Сайта, обеспечение надлежащего функционирования Сайта, улучшение Сайта, предоставление возможности организации и проведения опросов Респондентов посредством функционала Сайта, оказание технической поддержки Сайта, информирование Пользователей о существенно значимых обстоятельствах при использовании Сайта.</p>
<p><strong>3.3.</strong> Администрация сайта в качестве лица, действующего по поручению Пользователя вправе обрабатывать следующие Персональные данные Респондентов, участвующих в опросах, сформированных и проводимых Пользователем посредством Сайта: фамилия, имя, отчество, адрес электронной почты, IP-адрес, данные о действиях Респондентов на Сайте (в т.ч. данные, собираемые посредством встроенных на Сайте метрических программ), данные, указываемые/загружаемые Респондентами на Сайте в ходе прохождения опросов, проводимых Пользователем.</p>
<p><strong>3.4.</strong> Указанные в п. 3.3 Политики конфиденциальности Персональные данные Респондентов обрабатываются Администрацией сайта с целью предоставления Пользователю возможности организации и проведения опросов Респондентов, участвующих в опросах, сформированных и проводимых Пользователем посредством Сайта.</p>
<p><strong>3.5.</strong> Администрация сайта не осуществляет обработку Персональных данных для цели автоматизированного принятия решения (принятия решений, порождающих юридические последствия, затрагивающих права Субъектов ПДн и законные интересы), включая профилирование.</p>
<p><strong>3.6.</strong> Администрация сайта не осуществляет обработку Персональных данных Субъектов ПДн для цели прямого маркетинга (в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с Субъектами ПДн с помощью средств связи, а также в целях политической агитации).</p>
<p><strong>3.7.</strong> Администрация сайта не получает Персональные данные из общедоступных источников, не осуществляет распространение Персональных данных. Администрация сайта не осуществляет трансграничную передачу Персональных данных.</p>
<p><strong>3.8.</strong> Администрация сайта не осуществляет обработку специальных категорий персональных данных, биометрических персональных данных.</p>
<p><strong>3.9.</strong> Администрация сайта получает Персональные данные непосредственно от Пользователя (Представителя Пользователя) или Респондентов посредством Сайта, а также посредством сбора с использованием метрических программ, встроенных на Сайте.</p>
<p style="text-align: center"><strong>4.</strong> <strong>Основания, способы обработки персональных данных</strong></p>
<p><strong>4.1.</strong> Администрация сайта может обрабатывать указанные в разделе 3 Политики конфиденциальности Персональные данные исключительно в том случае, если применяется одно из следующих правовых оснований для такой обработки:</p>
<p><strong>4.1.1.</strong> В рамках исполнения Администрацией сайта условий лицензионного&nbsp;договора и/или Пользовательского соглашения, стороной которого либо выгодоприобретателем или поручителем по которому является Субъект ПДн, а также в рамках заключения такого договора по инициативе Субъекта ПДн или договора, по которому Субъект ПДн будет являться выгодоприобретателем или поручителем.</p>
<p><strong>4.1.2.</strong> По поручению Пользователя&nbsp;на основании заключенного с Пользователем лицензионного договора и/или Пользовательского соглашения.</p>
<p><strong>4.1.3.</strong> Если обработка необходима для того, чтобы Администрация сайта могла осуществить свои права и законные интересы (в частности, осуществлять права и исполнять обязательства перед Пользователем по Соглашению), либо способствовать осуществлению прав и законных интересов третьих лиц (в частности, других Пользователей). Перед осуществлением такой обработки Администрация сайта всегда проверяет, не будут ли нарушаться права и свободы Субъектов ПДн.</p>
<p><strong>4.1.4.</strong> При наличии согласия Субъекта ПДн на обработку его персональных данных.</p>
<p><strong>4.1.5.</strong> Для исполнения обязанностей, возложенных на Администрацию сайта законодательством РФ.</p>
<p><strong>4.1.6.</strong> При наличии иного правового основания, предусмотренное законодательством РФ.</p>
<p><strong>4.2.</strong> Обработка Персональных данных может осуществляться Администрацией сайта как с использованием средств автоматизации, так и без использования таких средств и может включать в себя следующие действия или совокупность действий: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение ПДн.</p>
<p style="text-align: center"><strong>5.</strong> <strong>Передача персональных данных</strong></p>
<p><strong>5.1.</strong> Администрация сайта не осуществляет передачу Персональных данных Пользователя (Представителя Пользователя) или Респондентов другим Пользователям, в том числе их Представителям.</p>
<p><strong>5.2.</strong> В рамках предоставления функционала Сайта Администрация сайта вправе осуществлять передачу (предоставление, доступ) Персональных данных Субъектов ПДн третьим лицам, привлекаемым Администрацией сайта на основании заключенных договоров для оказания содействия Администрации сайта в предоставлении услуг Пользователю в рамках Соглашения, а также в праве осуществлять передачу государственным органам по основаниям, предусмотренным законодательством РФ в случаях, предусмотренных п. 4.1 Политики конфиденциальности.</p>
<p style="text-align: center"><strong>6.</strong> <strong>Сроки обработки персональных данных</strong></p>
<p><strong>6.1.</strong> Обработка (включая хранение) Персональных данных Субъектов ПДн осуществляется до достижения одного из следующих событий:</p>
<p><strong>6.1.1.</strong> Прекращение действия Соглашения, закрытие Пользователю доступа к Сайту в соответствии с условиями Соглашения.</p>
<p><strong>6.1.2.</strong> Получение уведомления Администрацией сайта от Пользователя с требованием о прекращении обработки персональных данных Респондентов.</p>
<p><strong>6.1.3.</strong> Достижение целей обработки, установленных настоящей Политикой конфиденциальности и Пользовательским соглашением, или утрата необходимости в их достижении.</p>
<p><strong>6.2.</strong> При наступлении указанных выше обстоятельств, Администрация сайта прекращает обработку и уничтожает Персональные данные Субъектов ПДн в срок до 30 (тридцати) дней или иной более короткий срок (до 10 рабочих дней), предусмотренный законодательством РФ, если иное не предусмотрено договором (Соглашением), стороной которого, выгодоприобретателем или поручителем по которому является Субъект ПДн, либо если Администрация не вправе осуществлять обработку персональных данных без согласия Субъекта ПДн на основаниях, предусмотренных Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; или другими федеральными законами.</p>
<p><strong>6.3.</strong> В случае отсутствия у Администрации сайта возможности уничтожения Персональных данных в течение срока, указанного в п.6.2. Политики конфиденциальности, Администрация сайта осуществляет блокирование таких персональных данных и обеспечивает уничтожение персональных данных в срок не более чем шесть месяцев, если иной срок не установлен федеральными законами.</p>
<p style="text-align: center"><strong>7.</strong> <strong>Права Субъектов ПДн и обязанности Администрации сайта</strong></p>
<p><strong>7.1.</strong> Пользователь вправе лично или через своего уполномоченного представителя:</p>
<p><strong>7.1.1.</strong> Запросить у Администрации сайта подтверждение факта обработки его Персональных данных.</p>
<p>В случае такого запроса Пользователь (Представитель Пользователя) имеет право на доступ к обрабатываемым Персональным данным - право на получение сведений, касающихся обработки его Персональных данных, в частности:</p>

    <p> - обрабатываемые Персональные данные (перечень Персональных данных по п.2.1. ч.3 ст. 18 Федерального Закона от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;) и источник их получения;</p>
    <p> - правовые основания обработки Персональных данных;</p>
    <p> - цели и применяемые Администрацией сайта способы обработки Персональных данных;</p>
    <p> - наименование и место нахождения Администрации сайта;</p>
    <p> - сведения о лицах (за исключением работников Администрации сайта), которые имеют доступ к Персональным данным или которым могут быть раскрыты Персональные данные;</p>
    <p> - сроки обработки Персональных данных, в том числе сроки их хранения;</p>
    <p> - порядок осуществления Администрацией сайта прав Субъектов ПДн, предусмотренных законодательством РФ;</p>
    <p> - информацию об осуществленной или о предполагаемой трансграничной передаче Персональных данных;</p>
    <p> - информацию о способах исполнения Администрацией сайта обязанностей, установленных ст. 18.1 Федерального закона от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;;</p>
    <p> - иные сведения, предусмотренные законодательством РФ.</p>

<p>В случае направления запроса согласно настоящего пункта Политики конфиденциальности, такой запрос должен содержать:</p>

    <p> - номер основного документа, удостоверяющего личность Пользователя (Представителя Пользователя), сведения о дате выдачи указанного документа и выдавшем его органе;</p>
    <p> - сведения, подтверждающие участие Пользователя (Представителя Пользователя) в отношениях с Администрацией сайта, либо сведения, иным образом подтверждающие факт обработки Персональных данных Администрацией сайта;</p>
    <p> - подпись Пользователя (Представителя Пользователя).</p>

<p>Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.</p>
<p>Администрация сайта предоставляет указанные сведения Субъекту ПДн в течение десяти рабочих дней с момента обращения, либо получения Администрацией сайта запроса Пользователя (Представителя Пользователя) в той форме, в которой направлены соответствующие обращение либо запрос, если иное не указано в обращении или запросе.</p>
<p><strong>7.1.2.</strong> Потребовать уточнения Персональных данных, их удаления в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки.</p>
<p><strong>7.1.3.</strong> Обратиться к Администрации сайта с требованием о прекращении обработки персональных данных.</p>
<p><strong>7.1.4.</strong> Обратиться в суд с иском к Администрации сайта при обнаружении нарушений прав Пользователя (Представителя Пользователя) в соответствии с правилами подсудности, установленными законодательством РФ.</p>
<p><strong>7.2.</strong> Перечисленные в настоящем разделе Политики конфиденциальности права Пользователя (Представителя Пользователя) действуют в случаях и в пределах, предусмотренных законодательством РФ о персональных данных.</p>
<p><strong>7.3.</strong> Пользователь (Представитель Пользователя), а также Респонденты имеют права в области обработки персональных данных и неприкосновенности частной жизни, предоставленные им законодательством РФ о персональных данных.</p>
<p><strong>7.4.</strong> Администрация сайта обязана:</p>
<p><strong>7.4.1.</strong> Обрабатывать полученные Персональные данные Субъектов ПДн исключительно для целей, указанных в разделе 3 Политики конфиденциальности.</p>
<p><strong>7.4.2.</strong> Обеспечить хранение Персональных данных Субъектов ПДн на территории РФ, а также не осуществлять их продажу, обмен, опубликование, либо разглашение иными возможными способами, за исключением передачи в соответствии с разделом 5 Политики конфиденциальности.</p>
<p><strong>7.4.3.</strong> Осуществлять обработку Персональных данных Субъектов ПДн, а также обеспечить конфиденциальность и защиту обрабатываемых Персональных данных в соответствии с требованиями законодательства РФ и раздела 8 Политики конфиденциальности.</p>
<p><strong>7.4.4.</strong> Исполнять иные обязанности, возложенные на Администрацию сайта законодательством РФ.</p>
<p style="text-align: center"><strong>8. Защита персональных данных</strong></p>
<p><strong>8.1.</strong> Доступ к Персональным данным Субъектов ПДн может быть предоставлен только тем работникам Администрации сайта, которым он действительно нужен для исполнения их трудовых обязанностей.</p>
<p><strong>8.2.</strong> Для обеспечения безопасности и конфиденциальности обрабатываемых Персональных данных такие работники Администрации сайта также обязаны соблюдать внутренние нормативные документы, устанавливающие правила и процедуры в отношении обработки Персональных данных Субъектов ПДн - в том числе, направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений, проведение внутреннего контроля соответствия обработки Персональных данных требованиям законодательства РФ, а также устанавливающие организационные и технические меры по обеспечению безопасности таких данных.</p>
<p><strong>8.3.</strong> Администрация сайта принимает меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных законодательством РФ, включая:</p>

    <p> - назначение ответственного за организацию обработки Персональных данных;</p>
    <p> - издание документов, определяющих политику Администрации сайта в отношении обработки Персональных данных, локальных актов по вопросам обработки Персональных данных;</p>
    <p> - применение мер по обеспечению безопасности персональных данных, осуществление внутреннего контроля и (или) аудита соответствия обработки Персональных данных требованиям законодательства РФ и локальных актов Администрации сайта;</p>
    <p> - ознакомление работников Администрации сайта, непосредственно осуществляющих обработку Персональных данных, с положениями законодательства РФ, в том числе требованиями к защите Персональных данных, документами, определяющими политику Администрации сайта в отношении обработки Персональных данных, локальными актами по вопросам обработки Персональных данных, и (или) обучение указанных работников.</p>

<p><strong>8.4.</strong> Администрация сайта принимает (и, там, где это необходимо в случае передачи Персональных данных, обеспечивает их принятие получателем Персональных данных) все необходимые правовые, организационные и технические меры, предусмотренные законодательством РФ, для защиты Персональных данных от неправомерного или случайного доступа к ним, удаления, изменения, копирования, предоставления, распространения Персональных данных, а также от иных неправомерных действий в их отношении.</p>
<p style="text-align: center"><strong>9.</strong> <strong>Дополнительные условия</strong></p>
<p><strong>9.1.</strong> Администрация сайта вправе вносить изменения в Политику конфиденциальности в любое время.</p>
<p><strong>9.2.</strong> Администрация сайта информирует Пользователя об изменениях Политики конфиденциальности путем опубликования новой версии Политики конфиденциальности на Сайте.</p>
<p><strong>9.3.</strong> Пользователь (Представитель Пользователя) обязуется самостоятельно отслеживать изменения Политики конфиденциальности и осуществлять своевременное ознакомление с ней.</p>
<p><strong>9.4.</strong> При несогласии Пользователя (Представителя Пользователя) с соответствующими изменениями Политики конфиденциальности он обязан прекратить использование Сайта и отказаться от использования программы для ЭВМ &laquo;testograf 2.0&raquo;. В противном случае продолжение использования Пользователем (Представителем Пользователя) программы для ЭВМ &laquo;testograf 2.0&raquo; и Сайта означает, что Пользователь (Представитель Пользователя) согласен с условиями Политики конфиденциальности в новой редакции.</p>
<p><strong>9.5.</strong> Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
<p><strong>9.6.</strong> Политика конфиденциальности доступна при регистрации Пользователя (Представителя Пользователя) в личном кабинете Сайта, а также доступна по адресу <a href="https://www.testograf.ru/ru/docs-privacy-popcy" target="_blank">https://www.testograf.ru/ru/docs-privacy-popcy</a>.</p>
<p><strong>9.7.</strong> Все предложения или вопросы касательно Политики конфиденциальности следует направлять на адрес электронной почты info@testograf.ru.</p>
<p><strong>9.8.</strong> Настоящая Политика конфиденциальности была составлена на русском языке. В случае наличия каких-либо несоответствий между версией Политики, составленной на русском языке, и версией Политики, переведенной на другой язык, приоритет и прямое применение будут иметь положения Политики, составленной на русском языке.</p>
</div>`;
