import { NextSeo } from 'next-seo';

import { Advs } from 'components/ui/advs';

import css from './opportunities.css';

const tests = [
    {
        title: 'Тест: Контент и email-маркетинг',
        href: 'https://www.testograf.ru/ru/blog/content-email-marketing-test-template'
    },
    {
        title: 'Тестирование: Английский язык, уровень Advanced',
        href: 'https://www.testograf.ru/ru/blog/advanced-english-test-template'
    },
    {
        title: 'Тестирование: Английский язык, уровень Intermediate',
        href: 'https://www.testograf.ru/ru/blog/intermediate-english-test-template'
    },
    {
        title: 'Тест: Заполнение СЗВ-ТД',
        href: 'https://www.testograf.ru/ru/blog/szv-td-test-template'
    },
    {
        title: 'Тест: отпуска и отпускные',
        href: 'https://www.testograf.ru/ru/blog/vacation-test-template'
    },
    {
        title: 'Тест по JavaScript для Middle-разработчика',
        href: 'https://www.testograf.ru/ru/blog/middle-javascript-developer-test-template'
    }
];

export const Tests: FC = () => (
    <div className={css.hr}>
        <NextSeo title='Онлайн тесты - Возможности онлайн тестирования сотрудников от Testograf.ru' />
        <h1>Онлайн тесты</h1>
        <p>
            Удобный и&nbsp;многофункциональный онлайн-инструмент testograf позволит полностью автоматизировать
            проведение тестов. Используйте шаблоны тестов как основу или разрабатывайте собственные уникальные тесты
            с&nbsp;нуля.
        </p>
        <p>
            В&nbsp;конструкторе доступны типы тестовых вопросов с&nbsp;одним или несколькими правильными ответами,
            а&nbsp;также балльные тесты с&nbsp;минимальным проходным баллом. Есть проверка текстового ответа
            и&nbsp;правильности ранжирования (порядка). Дополнительно, каждый тест может включать раздел
            с&nbsp;информацией о&nbsp;тестируемом сотруднике. Ограничьте время на&nbsp;прохождение теста, используя один
            или несколько таймеров для разных блоков.
        </p>
        <p>
            Для распространения теста есть email-рассылка с&nbsp;сервиса с&nbsp;привязкой email тестируемого к&nbsp;его
            ответу. Вы&nbsp;также можете настроить отображение результатов для каждого сотрудника с&nbsp;указанием
            верных и&nbsp;неверных ответов, что улучшит обратную связь и&nbsp;поддержит мотивацию.
        </p>
        <Advs items={tests} title='Шаблоны профессиональных тестов' />
    </div>
);
