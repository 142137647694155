import { CorporateContactJsonLd } from 'next-seo';

import { AtSign } from '@webapp/ui/lib/icons';

import CallPhoneIcon from 'components/ui/icons/call-phone.svg';
import PinIcon from 'components/ui/icons/pin.svg';
import TimeIcon from 'components/ui/icons/time.svg';

import css from './contacts.css';

export const Contacts: FC = () => (
    <div className={css.contacts}>
        <h1 className={css.header}>Контакты</h1>
        <CorporateContactJsonLd
            logo='https://www.testograf.ru/logo.png'
            url='https://testograf.ru/'
            contactPoint={[
                {
                    telephone: '+7-495-120-65-19',
                    contactType: 'клиентский отдел',
                    email: 'info@testograf.ru',
                    areaServed: 'RU',
                    availableLanguage: ['Russian']
                }
            ]}
        />
        <h2 className={css.subheader}>Юридический адрес</h2>
        <div className={css.icons}>
            <PinIcon />
            <div className={css.address}>
                142034, МО, п. Мещерино, мкр. Южные горки, квартал 6, д. 45/2 адрес для почтовых уведомлений
            </div>
            <CallPhoneIcon />
            <div className={css.blue}>
                <a href='phone:74951206519'>+7 495 120 65 19</a>
            </div>
            <AtSign />
            <div className={css.blue}>
                <a href='mailto:info@testograf.ru'>info@testograf.ru</a>
            </div>
            <TimeIcon />
            <div>пн - пт 10.00 - 18.00 Мск</div>
        </div>
        <h2 className={css.subheader}>Реквизиты</h2>
        <div className={css.text}>Общество с ограниченной ответственностью «ТЕСТОГРАФ»</div>
        <div className={css.requisites}>
            <div>ИНН</div>
            <div>5003118105</div>
            <div>КПП</div>
            <div>500301001</div>
            <div>ОГРН</div>
            <div>1165003051201</div>
            <div>ОКВЭД</div>
            <div>62.01</div>
            <div />
            <div />
            <div>Р.сч</div>
            <div>40702810940000019022</div>
            <div>К.сч.</div>
            <div>30101810400000000225</div>
            <div>БИК</div>
            <div>044525225</div>
            <div />
            <div className={css.fromSecond}>в ПАО «Сбербанк» г. Москва</div>
        </div>
    </div>
);
