// TODO use entries from routes

import { StaticRoutes } from '@webapp/landing/src/resources/landing-routes';

export interface Link {
    name: string;
    path?: string;
    links: Array<Link>;
}

export const menuLinks = [
    {
        name: 'О сервисе',
        path: StaticRoutes.about.path
    },
    {
        name: 'Возможности',
        links: [
            {
                name: 'Опросы',
                links: [
                    {
                        name: 'Для HR',
                        path: StaticRoutes.capabilitiesHr.path
                    },
                    {
                        name: 'Для маркетинга',
                        path: StaticRoutes.capabilitiesMarketing.path
                    }
                ]
            },
            {
                name: 'Тесты',
                path: StaticRoutes.capabilitiesTests.path
            },
            {
                name: 'Голосования',
                path: StaticRoutes.capabilitiesVotes.path
            },
            {
                name: 'E-mail рассылка',
                path: StaticRoutes.capabilitiesMailing.path
            },
            {
                name: 'API',
                path: StaticRoutes.capabilitiesApi.path
            }
        ]
    },
    {
        name: 'Шаблоны',
        links: [
            {
                name: 'Шаблоны опросов',
                path: StaticRoutes.templates.path
            }
        ]
    },
    {
        name: 'Преимущества',
        path: StaticRoutes.advantagesSafety.path
    },
    {
        name: 'Функционал',
        path: StaticRoutes.functions.path
    },
    {
        name: 'Тарифы',
        links: [
            {
                name: 'Тарифные планы',
                path: StaticRoutes.pricingPlans.path
            },
            {
                name: 'Порядок подключения',
                path: StaticRoutes.pricingConnectionSteps.path
            },
            {
                name: 'Способы оплаты',
                path: StaticRoutes.pricingPaymentVariants.path
            }
        ]
    },
    {
        name: 'Договоры',
        links: [
            {
                name: 'Шаблон договора',
                path: StaticRoutes.docsLicence.path
            },
            {
                name: 'Политика ПДн',
                path: StaticRoutes.pdPolicy.path
            },
            {
                name: 'Пользовательское',
                path: StaticRoutes.docsEula.path
            },
            {
                name: 'Конфиденциальность',
                path: StaticRoutes.docsPrivacyPolicy.path
            },
            {
                name: 'Оферта',
                path: StaticRoutes.docsPublicOffer.path
            }
        ]
    },
    {
        name: 'Разработчикам',
        links: [
            {
                name: 'CSS стили',
                path: StaticRoutes.docsCustomCss.path
            },
            {
                name: 'Документация API',
                path: StaticRoutes.docsPrivateApi.path
            },
            {
                name: 'Веб-хуки',
                path: StaticRoutes.docsWebHooks.path
            }
        ]
    },
    {
        name: 'Блог',
        path: StaticRoutes.blog.path
    },
    {
        name: 'Контакты',
        path: StaticRoutes.contacts.path
    }
] as Array<Link>;
