export const publicOfferHTML = `<div class="docHTML">
<p>Настоящий документ представляет собой официальное предложение ООО &laquo;ТЕСТОГРАФ&raquo; заключить лицензионный договор на изложенных ниже условиях.&nbsp;</p>

    <p style="text-align: center"><strong>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</strong>

            <p><strong>1.1</strong> В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и определения:</p>
            <p><strong>1.2</strong> Оферта &ndash; текст настоящего документа со всеми приложениями, изменениями и дополнениями к нему, размещенный и/или доступный в сети Интернет по адресу: https://www.testograf.ru/ru/public-offer&nbsp;</p>
            <p><strong>1.3</strong> Договор &ndash; лицензионный договор о предоставлении права использования Программы, заключенный между Лицензиаром и Лицензиатом в порядке и на условиях, предусмотренных настоящей Офертой, включая Обязательные документы.</p>
            <p><strong>1.4</strong> Стороны &ndash; Лицензиат, Лицензиар.&nbsp;</p>
            <p><strong>1.5</strong> Лицензиат &ndash; лицо, способное совершить Акцепт Оферты (применительно к порядку заключения Договора) либо совершившее Акцепт Оферты (применительно к исполнению заключенного Договора).</p>
            <p><strong>1.6</strong> Лицензиар &ndash; ООО &laquo;ТЕСТОГРАФ&raquo;, ИНН/КПП 5003118105/500301001, 142034, МО, пос.Мещерино, мкр.Южные горки, Квартал 6, д.45/2.</p>
            <p><strong>1.7</strong> Программа – программа для ЭВМ «testograf 2.0», свидетельство о государственной регистрации программы для ЭВМ № 2019618230, запись в реестре программного обеспечения РФ № 13533 от 11.05.2022, представляющая собой интернет-сервис для создания онлайн и оффлайн опросов, включая анкетирование, тестирование, голосование. и обработки результатов, доступный Пользователю через Сайт.</p>
            <p><strong>1.8</strong> Пользователь - лицо, фактически пользующееся от имени Лицензиата функциональными возможностями Программы. Условиями тарифов может предусматриваться максимально разрешенное Лицензиату количество Пользователей, в том числе, в определенной роли и/или одновременно использующих ту или иную функциональную возможность Программы.&nbsp;</p>
            <p><strong>1.9</strong> Лицензия &ndash; право использования Программы по функциональному назначению, ограниченное правом на воспроизведение, запуск и применение Программы на условиях Договора, включая Обязательные документы, и в пределах качественных и количественных параметров использования Программы, согласованных в Заказе.</p>
            <p><strong>1.10</strong> Тип Лицензии &ndash; обозначение (наименование) права использования Программы (Лицензии), предоставляемого на условиях определенного Тарифа. Типы Лицензий указываются в документах путем указания соответствующего Тарифа и/или дополнительной опции.</p>
            <p><strong>1.11</strong> Заказ &ndash; заказ Лицензиата на предоставление прав использования Программы (Лицензии), размещаемый Лицензиатом и согласуемый Сторонами в порядке, предусмотренном настоящей Офертой.</p>
            <p><strong>1.12</strong> Пробная лицензия &ndash; безвозмездная Лицензия, предоставляемая Лицензиату с целью ознакомления Лицензиата с функциональными возможностями Программы.&nbsp;</p>
            <p><strong>1.13</strong> Акцепт Оферты &ndash; полное и безоговорочное принятие настоящей Оферты путем совершения Лицензиатом действий, указанных в разделе 2 настоящей Оферты, создающее Договор между Лицензиатом и Лицензиаром.</p>
            <p><strong>1.14</strong> Личный кабинет – закрытый раздел Программы, к которому Пользователь получает доступ после прохождения регистрации и/или авторизации на Сайте. Личный кабинет предназначен для хранения персональной информации Пользователя, использования функциональных возможностей Программы и получения уведомлений.</p>
            <p><strong>1.15</strong> Аутентификационные данные &ndash; уникальная последовательность символов или иное средство, предназначенные для идентификации Пользователя при использовании Программы. В качестве Аутентификационных данных в Программе используются: логин (адрес электронной почты) и пароль.</p>
            <p><strong>1.16</strong> В настоящей Оферте могут быть использованы термины и определения, не определенные в п.1.2 – 1.15. Оферты. В этом случае толкование такого термина производится в соответствии с текстом Оферты. В случае отсутствия однозначного толкования термина или определения в тексте Оферты следует руководствоваться его толкованием, определенным: в первую очередь – документами, образующими Договор между Сторонами, во вторую очередь - законодательством Российской Федерации, и в последующем - обычаями делового оборота и научной доктриной.</p>
            <p><strong>1.17</strong> Любая ссылка в настоящей Оферте на пункт (раздел Оферты) и/или ее условия, означает соответствующую ссылку на настоящую Оферту (ее раздел) и/или ее условия.&nbsp;</p>

    </p>

    <p style="text-align: center"><strong>2. АКЦЕПТ ОФЕРТЫ И ЗАКЛЮЧЕНИЕ ДОГОВОРА</strong>

            <p><strong>2.1</strong> Акцептом настоящей Оферты в отношении Пробной лицензии признается начало использования Программы посредством первой авторизации в Личном кабинете.&nbsp;</p>
            <p><strong>2.2</strong> Акцептом настоящей Оферты в отношении возмездных Лицензий признается оформление Лицензиатом в установленном настоящей Офертой порядке Заказа на предоставление соответствующей Лицензии и оплата соответствующего лицензионного вознаграждения.</p>
            <p><strong>2.3</strong> Договор считается заключенным с момента получения Лицензиаром Акцепта Лицензиата Оферты.&nbsp;</p>
            <p><strong>2.4</strong> Оплата Лицензиатом лицензионного вознаграждения за использование Программы дополнительно подтверждает полномочия соответствующего Пользователя на совершение от имени Лицензиата действий по заключению и исполнению Договора.&nbsp;</p>
            <p><strong>2.5</strong> На основании настоящей Оферты с Лицензиатом может быть заключено неограниченное количество Договоров.</p>

    </p>
    <p style="text-align: center"><strong>3. ПРЕДМЕТ ДОГОВОРА И ОБЩИЕ ПОЛОЖЕНИЯ</strong>

            <p><strong>3.1</strong> По Договору, заключенному на основании настоящей Оферты, Лицензиар обязуется предоставить Лицензиату на условиях простой (неисключительной) лицензии право использования Программы (Лицензию), а Лицензиат обязуется уплачивать лицензионное вознаграждение и соблюдать установленные ограничения использования Программы.</p>
            <p><strong>3.2</strong> За исключением Пробной лицензии, права использования Программы (Лицензии) предоставляются за вознаграждение на основании оформленных Лицензиатом Заказов. Объем (ограничения) доступных Лицензиату функциональных возможностей Программы, размер и порядок оплаты лицензионного вознаграждения, а также иные условия предоставления права использования Программы по Заказу определяются в соответствии с действующими Тарифами и Типом Лицензии, и/или количеством Пользователей, указанном Лицензиатом при оформлении Заказа.</p>
            <p><strong>3.3</strong> Заказы на предоставление прав использования Программы (Лицензий) могут быть оформлены Лицензиатом путем:</p>
            <p><strong>3.4</strong> совершения Пользователем в Личном кабинете действий по выбору Тарифов и иных действий, необходимых для оформления Заказа;</p>
            <p><strong>3.5</strong> обращения уполномоченных лиц Лицензиата к Лицензиару иным образом в письменной форме, в том числе, по электронной почте.</p>
            <p><strong>3.6</strong> При выставлении Лицензиаром по Заказу счета на оплату лицензионного вознаграждения, условия Заказа, в том числе, применимый Тариф, срок действия Лицензии, количество Пользователей, и/или иные условия предоставления права использования Программы (Лицензии) по Заказу, могут указываться в таком счете.</p>
            <p><strong>3.7</strong> Во избежание сомнений, оплата Лицензиатом лицензионного вознаграждения или использование им Программы на условиях соответствующего Тарифа, подтверждает факт согласования Сторонами и содержание Заказа.</p>
            <p><strong>3.8</strong> За исключениями, предусмотренными настоящим пунктом, срок, на который предоставляется право использования Программы (далее также &ndash; срок действия Лицензии), равен предусмотренному соответствующим Тарифом периоду, за который рассчитывается лицензионное вознаграждение (оплачиваемый, тарифицируемый период).&nbsp;</p>
            <p><strong>3.9</strong> Срок действия Пробной лицензии определяется заранее ограниченным объемом использования тестируемых функциональных возможностей Программы и в любом случае прекращается предоставлением платной Лицензии.</p>
            <p><strong>3.10</strong> В случае, если лицензионное вознаграждение рассчитывается в зависимости от объема использования функциональных возможностей Программы и оплачиваемый период Тарифом не предусмотрен (Разовая лицензия), такая Лицензия действует до исчерпания предусмотренного Тарифом объема использования Программы, но не более 1 (Одного) года.</p>
            <p><strong>3.11</strong> Заказом может быть предусмотрено предоставление и оплата Лицензии на несколько (более одного) тарифицируемых периодов. В этом случае Лицензия предоставляется на срок, равный оплаченным периодам.</p>
            <p><strong>3.12</strong> Лицензиат вправе в течение срока её действия заменить предоставленную Лицензию на Лицензию другого типа с дополнительными возможностями или расширения отдельных количественных и/или качественных параметров Лицензии по Тарифу путем оформления нового Заказа.&nbsp;</p>
            <p><strong>3.13</strong> Использование Программы Лицензиатом осуществляется путем удаленного доступа через сеть &laquo;Интернет&raquo; к Личным кабинетам Пользователей согласно условиям применимого Тарифа. Для создания Личного кабинета Лицензиат самостоятельно проходит процедуру регистрации в Программе, по окончании которой на указанную Лицензиатом электронную почту высылается логин и пароль для осуществления доступа.&nbsp;</p>
            <p><strong>3.14</strong> Обязательным условием заключения и исполнения Договора является безоговорочное принятие и соблюдение Лицензиатом применяемых к отношениям Сторон следующих требований и положений, определенных указанными ниже документами (&laquo;Обязательные документы&raquo;):</p>
            <p><strong>3.15</strong> Пользовательское соглашение, размещенное и/или доступное в сети Интернет по адресу <a href="https://www.testograf.ru/ru/docs-eula" target="_blank">https://www.testograf.ru/ru/docs-eula</a>, и включающее общие условия регистрации в Программе и ее использования.</p>
            <p><strong>3.16</strong> Политика конфиденциальности, размещенная и/или доступная в сети Интернет по адресу <a href="https://www.testograf.ru/ru/docs-privacy-policy" target="_blank">https://www.testograf.ru/ru/docs-privacy-policy</a> и содержащая правила предоставления Пользователями персональной информации и её использования Лицензиаром.</p>
            <p><strong>3.17</strong> Тарифы, размещенные и/или доступные в сети Интернет по адресу <a href="https://www.testograf.ru/ru/pricing-plans" target="_blank">https://www.testograf.ru/ru/pricing-plans</a> и/или предоставляемые Лицензиаром Лицензиату в письменной форме в индивидуальном порядке и применяемые Сторонами для расчета суммы лицензионного вознаграждения, подлежащего оплате Лицензиатом за предоставленное право использования Программы.&nbsp;</p>
            <p><strong>3.18</strong> Документация, размещенная и/или доступная в сети Интернет по адресу <a href="https://www.testograf.ru/ru/functions" target="_blank">https://www.testograf.ru/ru/functions</a>, в которой описывается логика работы Программы, а также технические требования, условия и инструкции по использованию Программы.&nbsp;</p>
            <p><strong>3.19</strong> Указанные в п. 3.8. Оферты обязательные для Сторон документы составляют неотъемлемую часть Договора, заключаемого на основании настоящей Оферты.</p>
            <p><strong>3.20</strong> При отсутствии между Сторонами договора, заключенного путем подписания единого документа, все счета, акты, электронные формы и прочие документы, выражающие содержание Договора, оплаченные, принятые или направленные Сторонами в период действия настоящей Оферты, считаются составленными и подлежащими исполнению в соответствии с условиями настоящей Оферты.</p>

    </p>

    <p style="text-align: center"><strong>4. УСЛОВИЯ ЛИЦЕНЗИРОВАНИЯ</strong>

            <p><strong>4.1</strong> Разрешенные способы использования Программы:</p>
            <p><strong>4.2</strong> Лицензиату предоставляется право на воспроизведение команд и данных, составляющих клиентскую часть Программы, на ЭВМ Лицензиата, Пользователей или третьих лиц исключительно в целях реализации прав по п. 4.1.2. настоящей Оферты и в пределах установленных ограничений (право на воспроизведение).</p>
            <p><strong>4.3</strong> Лицензиату предоставляется право на использование Программы по функциональному назначению согласно Документации, ограниченное правом на запуск и применение отдельных компонентов и/или совокупности данных и команд, составляющих клиентскую часть Программы, на ЭВМ Лицензиата, Пользователей, а также на информационных ресурсах Лицензиата (если применимо), и использование серверной части Программы путем удаленного доступа через сеть &laquo;Интернет&raquo; в пределах установленных ограничений (право на использование по функциональному назначению).</p>
            <p><strong>4.4</strong> Лицензиату предоставляется право передавать результаты использования Программы (результаты прохождения опросов) в собственную ИТ-инфраструктуру и (или) сторонние сервисы с использованием следующих методов (возможностей): Open API (открытый API), Webhook (Вебхуки), SMS-шлюз Лицензиара, внутренняя (штатная) функциональность Программы по отправке результатов прохождения опросов на указанный адрес электронной почты, а также с использованием иных интеграций с внешними сервисами, сервисами Лицензиара.</p>
            <p><strong>4.5</strong> Обязательными документами могут быть предусмотрены особенности использования отдельных функциональных возможностей Программы и/или её компонентов.</p>
            <p><strong>4.6</strong> Право использования Программы, предоставленное по Договору, не подлежат сублицензированию или уступке третьему лицу в ином порядке без предварительного согласия Лицензиара в письменной форме.</p>
            <p><strong>4.7</strong> Все права, специально и явно не предоставленные Лицензиаром по Договору, считаются не предоставленными. Во избежание сомнений, Лицензиату строго запрещено:</p>
            <p><strong>4.8</strong> использовать Программу способами и в целях, для которых Программа не предназначена, в том числе, для сбора персональных данных специальных категорий;</p>
            <p><strong>4.9</strong> модифицировать, адаптировать и изменять Программу и её компоненты способом, не предусмотренным в Документации;</p>
            <p><strong>4.10</strong> вскрывать технологию, эмулировать, создавать новые версии, изменять, декомпилировать, дизассемблировать, дешифровать, а также&nbsp;производить иные действия с Программой, имеющие целью получение информации о&nbsp;реализации используемых в ней алгоритмов;</p>
            <p><strong>4.11</strong> использовать Программу с нарушением условий Договора, включая Обязательные документы, в том числе копировать, предоставлять, раскрывать или иным способом делать Программу доступной третьим лицам иначе, чем в рамках использования Программы в соответствии с условиями Договора;</p>
            <p><strong>4.12</strong> удалять или скрывать уведомления об авторских и иных правах, включая уведомления третьих лиц, которые были включены в предоставленные Лицензиаром Программу или Документацию.</p>
            <p><strong>4.13</strong> Лицензиар сохраняет за собой право контролировать любыми способами соблюдение количественных и качественных ограничений использования Программы, установленных Договором.</p>
            <p><strong>4.14</strong> Любой экземпляр (копия) Программы на любом материальном носителе, включая все компоненты Программы, выполненный и/или применяемый с нарушением условий Договора, должен быть немедленно уничтожен, наличие у лица такого экземпляра (копии) Программы не считается правомерным владением экземпляром, разрешение на его использование прекращается.</p>
            <p><strong>4.15</strong> Если иные сроки не предусмотрены Заказом или Обязательными документами, право использования Программы на условиях определенного Тарифа (Лицензия определенного Типа) предоставляется в течение 1 (Одного) рабочего дня с момента согласования Заказа в установленном настоящей Офертой порядке и его оплаты.</p>
            <p><strong>4.16</strong> Лицензиар обязан одновременно с предоставлением Лицензии предоставить Лицензиату доступ к использованию Программы через сеть &laquo;Интернет&raquo; в соответствии с условиями Лицензии. В случае невозможности использования Программы на условиях Лицензии и/или выявления существенных недостатков в Программе, делающих невозможным её использование на условиях Лицензии, Лицензиат вправе в течение 2 (Двух) рабочих дней с момента, когда доступ к использованию Программы на условиях Лицензии должен был быть предоставлен, потребовать повторного предоставления в течение 30 (Тридцати) календарных дней. В противном случае право использования и доступ к использованию Программы считаются предоставленными Лицензиаром в полном соответствии с требованиями Договора.</p>

    </p>

    <p style="text-align: center"><strong>5. ЛИЦЕНЗИОННОЕ ВОЗНАГРАЖДЕНИЕ</strong>

            <p><strong>5.1</strong> За исключением Пробной Лицензии, за предоставляемые по Договору право использования Программы Лицензиат обязан уплачивать Лицензиару лицензионное вознаграждение в сумме, рассчитываемой Лицензиаром в соответствии с действующими Тарифами в зависимости от количества Пользователей и состава предоставленных функциональных возможностей Программы.</p>
            <p><strong>5.2</strong> Лицензионное вознаграждение НДС не облагается в связи с применением Лицензиаром упрощенной системы налогообложения.</p>
            <p><strong>5.3</strong> Стороны безоговорочно принимают и соглашаются на использование данных внутренней учетной системы Лицензиара для установления объема использования Программы Лицензиатом (количества Пользователей и состава предоставленных функциональных возможностей).</p>
            <p><strong>5.4</strong> Сумма лицензионного вознаграждения по Договору за Отчетный период рассчитывается Лицензиаром согласно Тарифам, действующим на дату оформления Заказа на предоставление Лицензии или расширении количественных и/или качественных параметров использования Программы согласно предоставленной Лицензии.</p>
            <p><strong>5.5</strong> При наличии соответствующей функциональности Программы, сведения об объеме использования Программы Лицензиатом и сумме лицензионного вознаграждения предоставляются Лицензиату в электронной форме в Личном кабинете.</p>
            <p><strong>5.6</strong> На основании произведенного расчета Лицензиар выставляет счет на оплату лицензионного вознаграждения и предоставляет его Лицензиату в виде электронного образа в Личном кабинете, а также направляет на электронную почту, указанную Лицензиатом.&nbsp;</p>
            <p><strong>5.7</strong> Если иной порядок не предусмотрен Тарифом или Заказом, Лицензиат уплачивает лицензионное вознаграждение авансовым платежом в размере 100 % (Сто процентов) суммы лицензионного вознаграждения по счету, выставленному Лицензиатом на основании Заказа.</p>
            <p><strong>5.8</strong> Оплата лицензионного вознаграждения может производиться путем безналичных перечислений на расчетный счет Лицензиара либо с использованием указанных в Программе платежных сервисов.</p>
            <p><strong>5.9</strong> Лицензиат считается исполнившим свои обязательства по&nbsp;оплате лицензионного вознаграждения с&nbsp;момента поступления денежных средств на&nbsp;расчетный счет Лицензиара в&nbsp;установленном размере при безналичной форме оплаты либо предоставления информации о совершенном Лицензиатом платеже оператором платежного сервиса, действующим на основании договора с Лицензиаром.</p>
            <p><strong>5.10</strong> Для обеспечения непрерывности использования Программы Лицензиат должен своевременно оплачивать лицензионное вознаграждение. В случае несвоевременной оплаты Лицензиар вправе приостановить доступ Лицензиата к использованию Программы полностью или частично до получения оплаты.</p>
            <p><strong>5.11</strong> В случае невозможности предоставления права использования Программы в порядке, предусмотренном Договором, лицензионное вознаграждение возвращается Лицензиату в течение 30 (Тридцати) рабочих дней с даты получения соответствующего письменного требования от Лицензиата.</p>
            <p><strong>5.12</strong> В случае отсутствия фактического использования Лицензиатом Программы по независящим от Лицензиара обстоятельствам (отсутствие у Лицензиата необходимости в использовании Программы, невозможность использования Программы, обусловленная техническими проблемами с оборудованием и услугами связи со стороны Лицензиата) либо при досрочном расторжении Договора по инициативе Лицензиата, уплаченное лицензионное вознаграждение возврату не подлежит.</p>
            <p><strong>5.13</strong> Лицензиар вправе в одностороннем порядке изменять Тарифы. Тарифы вступают в силу с даты их публикации на Сайте, если иное не установлено Лицензиаром при их размещении. При этом размер уже оплаченного или согласованного между Сторонами лицензионного вознаграждения не подлежит изменению. Фактическое использование Программы после указанной Лицензиаром даты начала применения новых Тарифов или оплата лицензионного вознаграждения, рассчитанного в соответствии с новыми Тарифами, признается принятием Лицензиатом соответствующего изменения Тарифов.</p>

    </p>

    <p style="text-align: center"><strong>6. ОТЧЕТНОСТЬ</strong>

            <p><strong>6.1</strong> За исключением Пробной лицензии, о предоставлении и/или использовании Лицензиатом прав использования Программы на условиях Договора Лицензиар составляет акт или универсальный передаточный документ (далее &ndash; Акт) не позднее 7 (Семи) дней с момента оплаты лицензионного вознаграждения.</p>
            <p><strong>6.2</strong> Стороны признают и безоговорочно соглашаются, что сведения об объеме использования Программы в Отчетном периоде указываются в Акте на основе данных внутренней учетной системы Лицензиара.</p>
            <p><strong>6.3</strong> Акт составляется на бумажном носителе и (или) в виде электронного документа, подписанного электронной подписью.</p>
            <p><strong>6.4</strong> Электронный образ (скан-копия) Акта в течение 7 (Семи) дней с момента его составления предоставляется Лицензиату в Личном кабинете или направляется по электронной почте по адресу, указанному в Личном кабинете. При использовании Сторонами оператора электронного документооборота Акт в виде электронного документа может направляться Лицензиату через такого оператора.</p>
            <p><strong>6.5</strong> В&nbsp;случае непредставления Лицензиатом Лицензиару письменных возражений в течение 5 (Пяти) рабочих дней с момента его получения, Акт считается согласованным Лицензиатом без замечаний с даты составления Акта.</p>
            <p><strong>6.6</strong> По запросу Лицензиата Акт и счет на оплату или выполненные на бумажном носителе копии электронных документов могут быть направлены по адресу, указанному Лицензиатом.</p>
            <p><strong>6.7</strong> В случаях и в порядке, предусмотренном законодательством, Лицензиар направляет кассовый чек на указанный Лицензиатом адрес электронной почты и/или абонентский номер телефона.</p>
            <p><strong>6.8</strong> Лицензиат не обязан представлять Лицензиару отчеты об использовании Программы.</p>

    </p>



    <p style="text-align: center"><strong>7. ГАРАНТИИ СТОРОН</strong>

            <p><strong>7.1</strong> Лицензиар подтверждает и гарантирует, что Лицензиар обладает правами на использование Программы в объеме, необходимом для надлежащего выполнения обязательств по Договору.</p>
            <p><strong>7.2</strong> Поскольку Программа находится на стадии постоянного изменения и обновления, форма и характер функциональных возможностей Программы могут время от времени меняться без предварительного уведомления Лицензиата. Лицензиар не обязан уведомлять Лицензиата обо всех изменениях Программы.</p>
            <p><strong>7.3</strong> В течение всего срока действия предоставленных прав использования Программы Лицензиаром предоставляется гарантийная техническая поддержка Программы в порядке и на условиях, определяемых Лицензиаром по своему усмотрению. Лицензиар приложит все разумные усилия для устранения выявленных недостатков в Программе в максимально короткий срок, однако не гарантирует, что все ошибки в Программе могут быть устранены.</p>
            <p><strong>7.4</strong> За исключением прямо предусмотренных Договором гарантий, права использования Программы предоставляются на условиях &laquo;как есть&raquo; и &laquo;как доступно&raquo;, Лицензиар не предоставляет никаких дополнительных явных или подразумеваемых гарантий относительно Программы или любых других материалов или услуг, представленных по Договору. В максимально допустимой согласно применимому законодательству степени Лицензиар определенно отказывается от любых гарантий и условий, выраженных, подразумеваемых или установленных законом, касательно Программы, включая, без ограничения, гарантии или условия товарного состояния Программы и её пригодности для определенных целей.</p>
            <p><strong>7.5</strong> Лицензиар не гарантирует, что Программа будет соответствовать каким-либо требованиям Лицензиата, не предусмотренным Договором, что Программа будет функционировать вместе с различными продуктами третьих лиц, что функционирование Программы будет бесперебойным, что в Программе отсутствуют ошибки и уязвимости, или что все ошибки и уязвимости в Программе будут своевременно исправлены или устранены.&nbsp;</p>
            <p><strong>7.6</strong> Лицензиар не гарантирует Лицензиату прохождение опросов желаемым Лицензиатом количеством респондентов.&nbsp;</p>
            <p><strong>7.7</strong> Лицензиар не гарантирует Лицензиату получение тех или иных ответов на вопросы опросов от респондентов. Ответы на вопросы опросов, полученные от респондентов, могут кардинальным образом отличаться от желаний и предположений Лицензиата.&nbsp;</p>
            <p><strong>7.8</strong> Лицензиар не вправе использовать опросы Лицензиата и их результаты в своих целях, они признаются и являются собственностью Лицензиата.</p>
            <p><strong>7.9</strong> Лицензиар вправе проводить профилактические работы в Программе, в связи с чем в указанное время Сайт и/или отдельные части Программы могут быть недоступны для использования. Время проведения профилактических работ &ndash; с 00:00 до 06:00 по московскому времени, не чаще 2-х (двух) раз в месяц. О проведении профилактических работ Лицензиар предварительно, не менее, чем за сутки до момента начала проведения работ, информирует Лицензиата посредством направления на адреса электронной почты Пользователя(-ей) соответствующего уведомления.</p>
            <p><strong>7.10</strong> Лицензиар обязуется оказывать авторизованным Пользователю(-ям) гарантийную поддержку, включающую помощь в решении технических проблем или неясностей в работе отдельных функций Программы. Подробная консультация по работе конструктора опросов и совместная работа над опросами и их результатами не входят в стоимость лицензии, предоставленной Лицензиату, и их предоставление осуществляется на платной основе в рамках дополнительных услуг, оказываемых Лицензиаром.&nbsp;</p>
            <p><strong>7.11</strong> Гарантийная поддержка осуществляется в рабочие дни с понедельника по пятницу с 10.00 до 18:00 (время московское) по электронной почте <a href="info@testograf.ru" target="_blank">info@testograf.ru</a> и телефону +7 (495)120-65-19. Гарантийная поддержка не предоставляется лицам, выступающим от имени Лицензиата, но не авторизованным в качестве Пользователя(-ей) по Договору.</p>
            <p><strong>7.12</strong> Лицензиат гарантирует, что использование Программы будет осуществляться с соблюдением Договора, включая Обязательные документы, и применимого законодательства.</p>
            <p><strong>7.13</strong> Лицензиат гарантирует и заверяет, что все действия, совершенные при помощи использования Личного(-ых) кабинета(-ов), считаются совершенными непосредственно Пользователем(-ями), если ранее Пользователь(-и) не сообщил(-и) Лицензиару о несанкционированности доступа третьих лиц к Личным кабинетам.</p>
            <p><strong>7.14</strong> Лицензиат обязуется поддерживать актуальность электронных адресов Пользователей.&nbsp;</p>
            <p><strong>7.15</strong> В случае необходимости смены электронного адреса или Пользователя, Пользователь направляет запрос со своего электронного адреса Договоре, на <a href="info@testograf.ru" target="_blank">info@testograf.ru</a> с указанием нового электронного адреса и Ф.И.О. нового Пользователя, которому необходимо передать доступ к Личному кабинету. Если у Лицензиата нет доступа к электронному адресу Пользователя, Лицензиат направляет официальное письмо с подписью и печатью ответственного лица на email Лицензиара с указанием нового электронного адреса и Ф.И.О. нового Пользователя, которому необходимо передать доступ к Личному кабинету.</p>
            <p><strong>7.16</strong> Лицензиар не несет ответственности за передачу Лицензиатом результатов использования Программы (результатов прохождения опросов в собственную ИТ-инфраструктуру и (или) сторонние сервисы с использованием методов (возможностей), регламентированных п. 4.4 Договора.</p>

    </p>



    <p style="text-align: center"><strong>8. ОГРАНИЧЕНИЯ И ОТВЕТСТВЕННОСТЬ СТОРОН</strong>

            <p><strong>8.1</strong> Лицензиат осознает, принимает и соглашается с тем, что никакое программное обеспечение не свободно от сбоев и ошибок, и что любое использование Программы осуществляется Лицензиатом на свой риск. Лицензиар не несет ответственность за любые убытки, причиненные Лицензиату или любому третьему лицу в связи с использованием или невозможностью использования Программы, включая, без ограничения, утрату или повреждение имущества, документов, потерю прибыли, дохода, данных или возможности использовать данные, даже в случае, если Лицензиар был предупрежден о возможности подобных убытков.&nbsp;</p>
            <p><strong>8.2</strong> Лицензиар не несет ответственность за способы, цели и также возможные результаты использования Программы Лицензиатом.</p>
            <p><strong>8.3</strong> Ответственность Лицензиара по Договору в любом случае ограничена возмещением причиненного Лицензиату реального ущерба в сумме, не превышающей суммы лицензионного вознаграждения, уплаченного Лицензиатом Лицензиару за право использования Программы в течение Отчетного периода, в котором произошло событие, являющееся основанием ответственности.</p>
            <p><strong>8.4</strong> Лицензиат несет ответственность за действия Пользователей и иных лиц, которым Лицензиатом предоставлен доступ к использованию Программы, как за свои собственные.</p>
            <p><strong>8.5</strong> Типы, содержание и формулировки вопросов опросов, типы и формы ответов, варианты ответов определяются Пользователем(-ями), в связи с чем Пользователь(-и) самостоятельно несет(-ут) ответственность за соответствие/несоответствие вопросов/заданий действующему законодательству.</p>
            <p><strong>8.6</strong> Опросы, создаваемые и проводимые Пользователем(-ями) не должны рекламировать конкурентные Сайту ресурсы и содержать ссылки на опросы, размещенные на таких ресурсах.&nbsp;</p>
            <p><strong>8.7</strong> Пользователю(-ям) запрещается осуществлять передачу логинов и паролей для входа в свой(-и) Личный(-ые) кабинет(-ы) третьим лицам, другим сотрудникам и между Пользователями. Полученные логины и пароли вправе использоваться только указанными в Договоре и/или счете Пользователями. В случае нарушения условий, определенных настоящим пунктом, Лицензиар вправе заблокировать Личный(-ые) кабинет(-ы) Лицензиата без возврата лицензионного вознаграждения.</p>
            <p><strong>8.8</strong> Пользователю(-ям) запрещается использовать функцию email-рассылки из Личного(-ых) кабинета(-ов) для рассылки сообщений респондентам, ранее не предоставлявшим письменного разрешения Пользователю на отправку таких сообщений. При поступлении в адрес Лицензиара претензий и замечаний со стороны третьих лиц, в адрес которых поступили сообщения из Личного(-ых) кабинета(-ов) Пользователя(-ей), о несанкционированности их отправки в адрес данных лиц, Лицензиар:</p>

    </p>

<p>- выносит Лицензиату предупреждение и требование о предоставлении доказательств о санкционированности рассылки;</p>
<p>- в случае отсутствия доказательств о санкционированности рассылки и/или повторного получения претензий и замечаний со стороны третьих лиц, в адрес которых поступили соответствующие сообщения из Личного(-ых) кабинета(-ов) Пользователя(-ей), Лицензиар блокирует все Личные кабинеты Лицензиата без возврата лицензионного вознаграждения.</p>
<p>- имеет право в судебном порядке требовать компенсацию за возможные убытки и претензии со стороны третьих лиц, в адрес которых поступили несанкционированные сообщения из Личного(-ых) кабинета(-ов) Пользователя(-ей), а также штрафные санкции со стороны государственных органов Российской Федерации</p>

        <p>Лицензиар вправе незамедлительно отказаться от исполнения Договора в полном объеме или&nbsp;&nbsp;в соответствующей части, в том числе заблокировать доступ Пользователя(-ей) к&nbsp;<br>Личному(-ым) кабинету(-ам), ограничить для него(них) сферу возможного использования Сайта, удалить Личный(-ые) кабинет(-ы) Пользователя(-ей), удалить любую информацию, размещенную Пользователем(-ями) в той или иной форме, в следующих случаях:</p>

<p>- в случае нарушения Лицензиатом условий об оплате лицензионного вознаграждения;&nbsp;</p>
<p>- в случае нарушения Лицензиатом условий в части соответствия содержания опросов/тестов требованиям действующего законодательства;</p>
<p>- в случае нарушения Лицензиатом интеллектуальных прав третьих лиц;</p>
<p>- в случае осуществления несанкционированных email-рассылок (спам-сообщений);&nbsp;</p>
<p>- в случае передачи логинов и паролей иным сотрудникам Лицензиата, третьим лицам или между Пользователями.</p>

        <p><strong>8.10</strong> При этом в указанных случаях возврат лицензионного вознаграждения не осуществляется.</p>
        <p><strong>8.11</strong> Применение мер ответственности не освобождает Стороны от исполнения своих обязательств.</p>


    <p style="text-align: center"><strong>9. ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА</strong>

            <p><strong>9.1</strong> Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору, вызванное обстоятельствами непреодолимой силы, возникшими после ее заключения. К таким обстоятельствам, в частности, Стороны относят: стихийные бедствия; природные и промышленные катастрофы; террористические акты; военные действия; гражданские беспорядки; принятие органами государственной власти или органами местного самоуправления актов, содержащих запреты или ограничения в отношении деятельности Сторон; отсутствие электроэнергии и/или сбои работы компьютерной сети; иные обстоятельства, которые не могут быть заранее предвидены или предотвращены и делают невозможным исполнение обязательств Сторон по Договору.</p>
            <p><strong>9.2</strong> Лицензиар не несет ответственности за ненадлежащее исполнение обязательств со стороны третьих лиц, в частности со стороны платежных систем, кредитных организаций (банков), хостеров и провайдеров, операторов связи и&nbsp;т.д. При этом Лицензиар прилагает все зависящие от него меры для избежания соответствующих негативных последствий от ненадлежащего исполнения обязательств со стороны третьих лиц. Лицензиар обязуется оказывать Лицензиату помощь и поддержку при разрешении конфликтных и спорных ситуаций с участием третьих лиц, привлекаемых в рамках использования Сайта, в частности платежных систем, кредитных организаций (банков), хостеров и провайдеров, операторов связи и&nbsp;т.д.</p>
            <p><strong>9.3</strong> При наступлении обстоятельств непреодолимой силы, препятствующих исполнению обязательств по Договору, срок оказания Сторонами своих обязательств переносится соразмерно времени действия таких обстоятельств, а также времени, требуемого для устранения их последствий, но не более 60 (Шестидесяти) календарных дней. В случае если обстоятельства непреодолимой силы продолжают действовать свыше указанного срока, либо когда при их наступлении обеим Сторонам становится очевидным, что они будут действовать более этого срока, Договор прекращает свое действие.</p>

    </p>
    <p style="text-align: center"><strong>10. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>

            <p><strong>10.1</strong> Функциональные возможности Программы позволяют Лицензиату собирать, хранить и обрабатывать информацию о Пользователях и иных физических и юридических лицах, в том числе, персональные данные.</p>
            <p><strong>10.2</strong> В рамках использования Программы в соответствии с требованиями п.3 ст.6 Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» (далее – Закон № 152) Лицензиат поручает Лицензиару в целях предоставления возможности организации и проведения опросов обработку следующих персональных данных (далее – ПДн) респондентов, участвующих в опросах, сформированных и проводимых Пользователем посредством Сайта: фамилия, имя, отчество, адрес электронной почты, IP-адрес, данные о действиях респондентов на Сайте (в т.ч. данные собираемые посредством встроенных на Сайте метрических программ), данные, указываемые/загружаемые респондентами на Сайте в ходе прохождения опросов, проводимых Пользователем. Конкретный состав данных, обработка которых поручается Лицензиару, может быть сокращён Лицензиатом по его усмотрению с учетом функциональных возможностей Программы.</p>
            <p><strong>10.3</strong> Лицензиару поручается осуществлять как автоматизированную, так и без использования средств автоматизации обработку ПДн респондентов с совершением следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ) третьим лицам, привлекаемым Лицензиаром на основании заключенных договоров для оказания содействия в предоставлении услуг Лицензиату в рамках Договора, блокирование, удаление, уничтожение ПДн.</p>
            <p><strong>10.4</strong> В целях исполнения своих обязательств по Договору Лицензиар предоставляет Пользователю техническую возможность по сбору согласий респондентов на обработку и поручение их обработки Лицензиару по форме, определенной Пользователем.</p>
            <p><strong>10.5</strong> Лицензиат гарантирует наличие согласий респондентов на поручение обработки их ПДн Лицензиару в целях, указанных в настоящем Договоре, а также на передачу ПДн третьим лицам, если такие лица привлекаются в рамках передачи результатов использования Программы (результатов прохождения опросов) с использованием методов (возможностей), регламентированных п.4.4 Договора.</p>
            <p><strong>10.6</strong> Лицензиат гарантирует, что ПДн респондентов получены законными способами, цели, указанные при сборе ПДн, соответствуют целям исполнения обязательств по Договору и поручения на обработку ПДн.</p>
            <p><strong>10.7</strong> Лицензиат гарантирует доведение до Лицензиара информации о необходимости прекращения обработки ПДн респондентов в случае наличия отзыва респондентом согласия на обработку его ПДн или отсутствия иных оснований обработки ПДн.</p>
            <p><strong>10.8</strong> Лицензиар обязуется соблюдать конфиденциальность ПДн, требования, предусмотренные ч. 5 ст. 18, ст. 18.1 Закона № 152, а также обеспечить защиту ПДн в соответствии с требованиями ст. 19 Закона № 152, принятыми в соответствии с ним нормативными правовыми актами, в частности:</p>
            <p><strong>10.9</strong> Применением организационных технических мер по обеспечению безопасности ПДн при их обработке в Программе.</p>
            <p><strong>10.10</strong> Установлением правил доступа к ПДн, обрабатываемым в Программе, а также обеспечением регистрации и учета действий, совершаемых с ПДн в Программе.</p>
            <p><strong>10.11</strong> Восстановлением ПДн, модифицированных или уничтоженных вследствие несанкционированного доступа к ним.</p>
            <p><strong>10.12</strong> Обнаружением фактов несанкционированного доступа к ПДн и принятием мер.</p>
            <p><strong>10.13</strong> Контролем за принимаемыми мерами по обеспечению безопасности ПДн, обрабатываемых в Программе.</p>
            <p><strong>10.14</strong> Лицензиар в сроки, предусмотренные ч. 3.1 ст. 21 Закона № 152, обязуется уведомлять Пользователя об установлении факта неправомерной или случайной передачи (предоставления, распространения, доступа) ПДн, повлекшей нарушение прав субъектов персональных данных. Предоставление информации осуществляется согласно установленной Роскомнадзором формы, размещенной на сайте Роскомнадзора.</p>
            <p><strong>10.15</strong> По достижении целей обработки ПДн или в случае утраты необходимости в достижении этих целей, а также в случае получения отзыва согласия на обработку ПДн и невозможности обеспечения правомерности их обработки, Стороны обязуются уничтожить или обеспечить прекращение обработки ПДн.</p>
            <p><strong>10.16</strong> Лицензиар несет ответственность перед Лицензиатом за действия, производимые при обработке ПДн респондентов, осуществляемые по поручению Лицензиата. Лицензиат в соответствии с требованиями ч.3 ст.6 Закона № 152 несет ответственность за обработку ПДн респондентов, в т.ч. за их обработку Лицензиаром перед государственными органами Российской Федерации и респондентами.</p>

    </p>

    <p style="text-align: center"><strong>11. СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ ОФЕРТЫ И ОБЯЗАТЕЛЬНЫХ ДОКУМЕНТОВ</strong>

            <p><strong>11.1</strong> Настоящая Оферта вступает в силу с момента размещения по указанному адресу в сети Интернет и действует до момента ее отзыва Лицензиаром.</p>
            <p><strong>11.2</strong> Лицензиар оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. Сведения об изменении или отзыве Оферты доводятся до Лицензиата по выбору Лицензиара: посредством размещения в Программе или путем направления соответствующего уведомления в Личном кабинете или на электронный или почтовый адрес, указанный Лицензиатом при заключении Договора или в ходе его исполнения.</p>
            <p><strong>11.3</strong> В случае отзыва Оферты или внесения изменений в Оферту, последние вступают в силу с момента размещения Оферты по указанному адресу в новой редакции, если иной срок вступления их в силу не определен Офертой или дополнительно при таком уведомлении.</p>
            <p><strong>11.4</strong> Если иное прямо не предусмотрено настоящей Офертой или соответствующим Обязательным документом, указанные в Оферте Обязательные документы утверждаются, дополняются и изменяются Лицензиаром по собственному усмотрению, доводятся до сведения Лицензиата в порядке, предусмотренном для уведомления Лицензиата об изменении Оферты.</p>
            <p><strong>11.5</strong> Изменения Обязательных документов вступают в силу с момента размещения их новой редакции, если иной срок вступления в силу изменений не определен настоящим Договором, Обязательными документами или дополнительно при уведомлении Лицензиата о таких изменениях.</p>

    </p>

    <p style="text-align: center"><strong>12. СРОК ДЕЙСТВИЯ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА</strong>

            <p><strong>12.1</strong> Договор вступает в силу с момента совершения Акцепта Лицензиатом и действует неограниченный период времени до его расторжения.</p>
            <p><strong>12.2</strong> Любая Сторона может расторгнуть Договор в любое время, предварительно письменно уведомив другую Сторону за 30 (Тридцать) календарных дней.</p>
            <p><strong>12.3</strong> Договор может быть расторгнут немедленно, с письменным уведомлением другой Стороны в случае наступления следующих событий:</p>
            <p><strong>12.4</strong> если другая Сторона прекращает ведение своей деятельности полностью или в части, связанной с исполнением Договора, или</p>
            <p><strong>12.5</strong> если другая Сторона нарушила какое-либо положение Договора и не смогла полностью исправить данное нарушение в течение 5 (Пяти) календарных дней с момента получения уведомления об этом от другой Стороны, или</p>
            <p><strong>12.6</strong> если в отношении другой Стороны начата процедура банкротства.&nbsp;</p>
            <p><strong>12.7</strong> В случае прекращения Договора (в том числе досрочного), Лицензиар хранит загруженные Лицензиатом данные и настройки Программы в течение 180 (Сто восемьдесят) календарных дней. Если в указанный срок Лицензиат не заключил новый Договор, Лицензиар вправе удалить имеющиеся данные и настройки Программы.</p>
            <p><strong>12.8</strong> Положения Договора, которые в силу своей природы или прямого указания должны продолжить свое действие, сохранят свое действие после прекращения действия всего Договора.</p>

    </p>

    <p style="text-align: center"><strong>13. СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ ЭЛЕКТРОННОЙ ПОДПИСИ</strong>

            <p><strong>13.1</strong> Стороны вправе использовать при оформлении счетов, направлении сообщений и уведомлений, а также ином взаимодействии по настоящему Договору простую электронную подпись.</p>
            <p><strong>13.2</strong> Простой электронной подписью признается электронная подпись, которая посредством использования Аутентификационных данных Лицензиата или указанного при использовании Программы адреса электронной почты Лицензиата (ключ электронной подписи) подтверждает факт формирования электронной подписи непосредственно Лицензиатом.</p>
            <p><strong>13.3</strong> При использовании Сторонами электронной почты направляемый с ее помощью электронный документ считается подписанным простой электронной подписью отправителя, созданной с использованием адреса его электронной почты. Получатель электронного документа определяет лицо, подписавшее такой документ, по используемому им адресу электронной почты.</p>
            <p><strong>13.4</strong> Лицензиат вправе подписывать электронные документы, передаваемые в рамках использования Программы, простой электронной подписью, созданной с использованием своих Аутентификационных данных. При совершении Лицензиатом любых действий по использованию Программы, Лицензиар определяет Лицензиата, которому соответствует простая электронная подпись, по используемым Лицензиатом Аутентификационным данным.</p>
            <p><strong>13.5</strong> Электронные документы, передаваемые Лицензиаром Лицензиату с использованием Программы, считаются подписанными простой электронной подписью Лицензиара.</p>
            <p><strong>13.6</strong> Проверка простой электронной подписи осуществляется путем направления Стороне соответствующего запроса.</p>
            <p><strong>13.7</strong> По соглашению Сторон электронные документы, подписанные простой электронной подписью, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью.</p>
            <p><strong>13.8</strong> Любые действия, совершенные с использованием простой электронной подписи Стороны, считаются совершенными такой Стороной.&nbsp;</p>
            <p><strong>13.9</strong> Стороны обязуются соблюдать конфиденциальность ключа электронной подписи. В частности, Лицензиат не имеет права передавать Аутентификационные данные или предоставлять доступ к Личному кабинету или электронной почте третьим лицам, и несет полную ответственность за их сохранность и индивидуальное использование, самостоятельно выбирая способ их хранения и ограничения к ним доступа.&nbsp;</p>
            <p><strong>13.10</strong> В случае несанкционированного доступа к Личному кабинету, утраты или раскрытия третьим лицам Аутентификационных данных, Лицензиат обязан незамедлительно сообщить об этом Лицензиару путем направления электронного письма с указанного при использовании Программы адреса электронной почты.</p>
            <p><strong>13.11</strong> В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан при использовании Программы, Лицензиат обязан незамедлительно заменить такой адрес на новый и сообщить о данном факте Лицензиару способом, позволяющим установить, что сообщение исходит от Лицензиата.</p>

    </p>

    <p style="text-align: center"><strong>14. ПРОЧИЕ УСЛОВИЯ</strong>

            <p><strong>14.1</strong> Договор, его заключение и исполнение регулируется действующим законодательством Российской Федерации. Все вопросы, не урегулированные настоящей Офертой или урегулированные не полностью, регулируются в соответствии с материальным правом Российской Федерации.&nbsp;</p>
            <p><strong>14.2</strong> Споры по Договору разрешаются в предварительном претензионном порядке.. Срок для ответа на предъявленную претензию составляет 10 (десять) рабочих дней с момента ее получения соответствующей Стороной. В случае недостижения Сторонами согласия, споры подлежат рассмотрению в суде по месту нахождения Лицензиара.</p>
            <p><strong>14.3</strong> Лицензиар вправе указывать Лицензиата и размещать его логотип в своих маркетинговых материалах, в том числе, в соответствующем разделе сайта Лицензиара, в качестве пользователя Программы. При этом Лицензиар обязуется соблюдать правила и условия размещения информации о Лицензиате, в т.ч. логотипа, если таковые будут предоставлены.</p>
            <p><strong>14.4</strong> Любые уведомления и документы по Договору, если иное не предусмотрено настоящей Офертой, включая Обязательные документы, могут направляться одной Стороной другой Стороне: 1) по электронной почте; 2) направления электронного уведомления в Личном кабинете; 3) почтой с уведомлением о вручении или курьерской службой с подтверждением доставки.&nbsp;</p>
            <p><strong>14.5</strong> В случае если одно или более положений Оферты или Договора являются по какой-либо причине недействительными, не имеющими юридической силы, такая недействительность не оказывает влияния на действительность любого другого положения Оферты или Договора, которые остаются в силе.</p>
            <p><strong>14.6</strong> Не вступая в противоречие с условиями Оферты, Стороны вправе в любое время оформить заключенный Договор в форме письменного документа, выражающего содержание действующей на момент его оформления Оферты, указанных в них Обязательных документов и размещенного заказа.</p>

    </p>

    <p style="text-align: center"><strong>15. РЕКВИЗИТЫ ЛИЦЕНЗИАРА</strong></p>
<p>ООО &laquo;ТЕСТОГРАФ&raquo; ИНН/КПП 5003118105/500301001 142034, МО, пос.Мещерино, мкр.Южные горки, Квартал 6, д.45/2 E-mail: <a href="info@testograf.ru" target="_blank">info@testograf.ru</a></p>
<p><br></p>
<p>Действующая редакция Оферты от 01.04.2024 г.</p>
</div>`;
