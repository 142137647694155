import { Button } from 'components/ui/button';

import { data } from './data';

import css from './templates.css';

const TemplateItem: FC<{
    title: string;
    href: string;
}> = ({ title, href }) => (
    <li className={css.templateItem} title={title}>
        <a href={href} rel='noreferrer' target={'_blank'}>
            {title}
        </a>
    </li>
);

const Section: FC<{
    section: {
        title: string;
        items: Array<{
            title: string;
            href: string;
        }>;
    };
}> = ({ section: { items, title } }) => (
    <>
        {title && <h4 className={css.subheader}>{title}</h4>}
        <ul>
            {items.map(({ title, href }) => (
                <TemplateItem key={title} href={href} title={title} />
            ))}
        </ul>
    </>
);

const Group: FC<{
    group: {
        icon: SVGIcon;
        title: string;
        sections: any;
    };
}> = ({ group: { icon: Icon, sections, title } }) => (
    <div>
        <div className={css.group}>
            {(Icon || title) && (
                <h3 className={css.groupHeader}>
                    {Icon && <Icon />}
                    {title && <span>{title}</span>}
                </h3>
            )}
            {sections.map((s) => (
                <Section key={s.title} section={s} />
            ))}
        </div>
    </div>
);

export const Templates: FC = () => (
    <>
        <h1>Примеры опросов и шаблоны анкет</h1>
        <p>
            Используйте профессиональные шаблоны анкет в имеющемся виде или редактируйте их, внося необходимые
            изменения. Шаблоны различных дизайнов опросов{' '}
            <a
                className={css.link}
                href='https://www.testograf.ru/ru/blog/designe-template'
                rel='noreferrer'
                target={'_blank'}
            >
                здесь
            </a>
            .
        </p>
        <div className={css.examples}>
            <div className={css.column}>
                {data.slice(0, 1).map((d) => (
                    <Group group={d} key={d.title} />
                ))}
            </div>
            <div className={css.column}>
                {data.slice(1, 3).map((d) => (
                    <Group group={d} key={d.title} />
                ))}
            </div>
            <div className={css.column}>
                {data.slice(3).map((d) => (
                    <Group group={d} key={d.title} />
                ))}
            </div>
        </div>
        <Button href='/auth/register'>Создать анкету</Button>
    </>
);
