import { NextSeo } from 'next-seo';

import { Advs } from 'components/ui/advs';

import css from './opportunities.css';

const templates = [
    {
        titleBig: 'Трудоустройство'
    },
    {
        title: 'Удовлетворенность процедурой приема на работу',
        href: 'https://www.testograf.ru/ru/blog/satisfaction-hiring-process-template'
    },
    {
        titleBig: 'Лояльность/Вовлеченность/Мотивация'
    },
    {
        title: 'Отношение к компании (опрос Липпонена)',
        href: 'http://testograf.ru/ru/blog/lipponen-survey-template'
    },
    {
        title: 'Уровень мотивации сотрудников',
        href: 'http://testograf.ru/ru/blog/employee-motivation-level-template'
    },
    {
        title: 'Тест на вовлеченность персонала (Gallup Q12)',
        href: 'http://testograf.ru/ru/blog/gallup-q12-survey-template'
    },
    {
        title: 'Вовлеченность сотрудников',
        href: 'http://testograf.ru/ru/blog/employee-engagement-template'
    },
    {
        title: 'Индекс лояльности сотрудников eNPS',
        href: 'https://www.testograf.ru/ru/blog/enps-survey-template'
    },
    {
        title: 'Тайм менеджмент',
        href: 'https://www.testograf.ru/ru/blog/time-management-survey-template'
    },
    {
        titleBig: 'Оценка / самооценка'
    },
    {
        title: 'Круговая оценка работы персонала (Метод «360 градусов»)',
        href: 'https://www.testograf.ru/ru/blog/survey-360-template'
    },
    {
        title: 'Круговая оценка персонала "360 градусов" по компетенциям',
        href: 'https://www.testograf.ru/ru/blog/360-degree-survey-template'
    },
    {
        title: 'Самооценка сотрудника',
        href: 'https://www.testograf.ru/ru/blog/employee-self-assessment-template'
    },
    {
        title: 'Карьерные ожидания сотрудников',
        href: 'https://www.testograf.ru/ru/blog/career-expectations-of-employees-survey-template'
    },
    {
        title: 'Диагностики синдрома выгорания',
        href: 'https://www.testograf.ru/ru/blog/burnout-syndrome-test-template'
    },
    {
        title: 'Оценка руководителя (опрос Google)',
        href: 'https://www.testograf.ru/ru/blog/manager-assessment-survey-template'
    },
    {
        titleBig: 'Удовлетворенность'
    },
    {
        title: 'Анкета удовлетворенности сотрудников',
        href: 'https://www.testograf.ru/ru/blog/employee-satisfaction-survey-template'
    },
    {
        title: 'Оценка удовлетворенности сотрудников (опрос Спектора)',
        href: 'https://www.testograf.ru/ru/blog/spector-survey-template'
    },
    {
        title: 'Миннесотский опрос: Удовлетворенность сотрудников',
        href: 'https://www.testograf.ru/ru/blog/minnesota-survey-employee-satisfaction-template'
    },
    {
        title: 'Удовлетворенность работой и вознаграждениями',
        href: 'https://www.testograf.ru/ru/blog/job-satisfaction-and-rewards-survey-template'
    },
    {
        title: 'Удовлетворенность условиями труда с оценкой важности',
        href: 'https://www.testograf.ru/ru/blog/employee-satisfaction-pro-survey-template'
    },
    {
        titleBig: 'Уходящий сотрудник'
    },
    {
        title: 'Опрос уходящего сотрудника',
        href: 'https://www.testograf.ru/ru/blog/exit-interview-template'
    },
    {
        titleBig: 'Прочее'
    },
    {
        title: 'Корпоративная культура',
        href: 'https://www.testograf.ru/ru/blog/corporate-culture-survey-template'
    },
    {
        title: 'Диагностика аврала',
        href: 'https://www.testograf.ru/ru/blog/diagnosis-of-emergency-work-template'
    }
];

export const HR: FC = () => (
    <div className={css.hr}>
        <NextSeo title='Опросы и тесты для HR - Возможности онлайн тестирования сотрудников от Testograf.ru' />
        <h1>Опросы и тесты для HR</h1>
        <p className={css.text}>
            Testograf позволяет автоматизировать проведение HR-опросов и&nbsp;тестов. Конструктор предоставляет
            профессиональный инструментарий для анкетирования и&nbsp;тестирования кандидатов на&nbsp;должность
            и&nbsp;существующего персонала, позволяет регулярно проводить оценку степени удовлетворенности
            и&nbsp;вовлеченности сотрудников.
            <br />
            <br /> Вы&nbsp;можете использовать шаблоны опросов и&nbsp;тестов для сотрудников либо создавать собственные
            анкеты с&nbsp;нуля. В&nbsp;конструкторе много типов вопросов, настройки логики показа блоков
            и&nbsp;завершений, email-рассылка с&nbsp;возможностью отправки напоминаний и&nbsp;тонкие настройки дизайна.
            <br />
            <br /> Сервис не&nbsp;только позволяет сократить время на&nbsp;проведение опросов,
            но&nbsp;и&nbsp;существенно упрощает работу с&nbsp;результатами. Результаты опросов и&nbsp;тестирований
            представлены в&nbsp;виде таблиц и&nbsp;диаграмм, по&nbsp;вариантам ответов доступны фильтры, можно
            просматривать общую статистику и&nbsp;отдельные ответы коллег. Останется только скрыть ненужную информацию
            и&nbsp;выгрузить отчёт для руководства в&nbsp;нужном формате.
        </p>
        <Advs items={templates} title='Шаблоны опросов и тестов для сотрудников' />
    </div>
);
