import { NextSeo } from 'next-seo';

import { Advs } from 'components/ui/advs';

import css from '../opportunities.css';

const templates = [
    {
        title: 'Индекс лояльности (NPS)',
        href: 'https://www.testograf.ru/ru/blog/nps-template'
    },
    {
        title: 'Индекс удовлетворённости CSI',
        href: 'https://www.testograf.ru/ru/blog/csi-survey-template'
    },
    {
        title: 'Анкета тайного покупателя',
        href: 'https://www.testograf.ru/ru/blog/mystery-shopper-survey-template'
    },
    {
        title: 'Оценка лояльности клиента',
        href: 'https://www.testograf.ru/ru/blog/customer-loyalty-assessment-template'
    },
    {
        title: 'Опрос клиентов о качестве обслуживания',
        href: 'https://www.testograf.ru/ru/blog/customer-service-quality-survey-template'
    },
    {
        title: 'Узнаваемость бренда',
        href: 'https://www.testograf.ru/ru/blog/brand-recognition-template'
    },
    {
        title: 'Анкета постоянного покупателя',
        href: 'https://www.testograf.ru/ru/blog/survey-regular-customer-template'
    },
    {
        title: 'Форма обратной связи для сайта',
        href: 'https://www.testograf.ru/ru/blog/feedback-form-template'
    }
];

export const Marketing: FC = () => (
    <div className={css.hr}>
        <NextSeo title='Опросы и тесты для маркетинга - Возможности онлайн тестирования сотрудников от Testograf.ru' />
        <h1>Опросы и тесты для маркетинга</h1>
        <p className={css.text}>
            Оптимизируйте работу отдела маркетинга, введя в&nbsp;практику использование онлайн-инструмента для
            проведения опросов testograf. Testograf предоставляет возможности для автоматизированного сбора
            и&nbsp;анализа мнений клиентов о&nbsp;ваших товарах и&nbsp;услугах, чтобы вы&nbsp;могли лучше понять
            их&nbsp;потребности. Сервис широко используется в&nbsp;маркетинге: для сбора обратной связи, при подготовке
            к&nbsp;запуску нового товара, при исследовании маркетинговых предпочтений и&nbsp;разработке нового дизайна
            упаковки.
            <br />
            <br />
            Одна из&nbsp;важнейших функциональных возможностей сервиса&nbsp;&mdash; его способность идентифицировать
            ответы клиентов. На&nbsp;сервисе есть email-рассылка с&nbsp;привязкой email к&nbsp;ответу клиента,
            в&nbsp;том числе с&nbsp;использованием своего SMTP-сервера, интеграция через API и&nbsp;добавление
            параметров ссылки. Всё это позволяет глубоко и&nbsp;разносторонне анализировать результаты клиентских
            опросов.
            <br />
            <br />
            Для создания нескучных и&nbsp;привлекательных форм доступны различные типы вопросов, настройка логики
            и&nbsp;полная кастомизация под бренд-бук вашей компании как с&nbsp;помощью настроек, так
            и&nbsp;с&nbsp;использованием CSS-стилей.
            <br />
            <br />
            Способы распространения опросов также включают в&nbsp;себя виджет, pop-up и&nbsp;встраивание опроса
            на&nbsp;сайт вашей компании.
        </p>
        <Advs items={templates} title='Шаблоны опросов клиентов' />
    </div>
);
