import { NextSeo } from 'next-seo';

import css from '../opportunities.css';

import emailImage from './email.webp';

export const Email: FC = () => (
    <div className={css.hr}>
        <NextSeo title='E-mail рассылка для опросов и голосований - Возможности онлайн тестирования сотрудников от Testograf.ru' />
        <h1>E-mail рассылка для опросов и голосований</h1>
        <p className={css.text}>
            Email рассылка является одним из наиболее популярных способов распространения опросов и тестов. На testograf
            вы можете загрузить различные базы электронных адресов, брендировать свои письма, планировать email рассылку
            и настраивать напоминания для тех, кто еще не ответил. Подробная статистика email рассылки будет полезной
            как при работе с самой рассылкой, так и при работе с результатами.
        </p>
        <p>
            <img alt='email' src={emailImage} />
        </p>
    </div>
);
