import { NextSeo } from 'next-seo';

import { Advs } from 'components/ui/advs';

import css from './opportunities.css';

const tests = [
    {
        title: 'Оценка изображения',
        href: 'https://www.testograf.ru/ru/blog/image-rating-vote-template'
    },
    {
        title: 'Выбор лучшего изображения',
        href: 'https://www.testograf.ru/ru/blog/best-image-vote-template'
    },
    {
        title: 'Выбор лучшего',
        href: 'https://www.testograf.ru/ru/blog/best-choice-vote-template'
    },
    {
        title: 'Анкета обратной связи после мероприятия',
        href: 'https://www.testograf.ru/ru/blog/event-feedback-template'
    },
    {
        title: 'Обратная связь после корпоративного мероприятия',
        href: 'https://www.testograf.ru/ru/blog/corporate-event-survey-template'
    },
    {
        title: 'Оценка спикера на конференции',
        href: 'https://www.testograf.ru/ru/blog/speaker-evaluation-vote-template'
    },
    {
        title: 'Голосование по переизбранию руководящих должностных лиц',
        href: 'https://www.testograf.ru/ru/blog/management-vote-template'
    }
];

export const Votes: FC = () => (
    <div className={css.hr}>
        <NextSeo title='Онлайн голосования - Возможности онлайн тестирования сотрудников от Testograf.ru' />
        <h1>Онлайн голосования</h1>
        <p className={css.text}>
            Testograf используется для организации онлайн-голосований на&nbsp;конференциях, вебинарах и&nbsp;других
            мероприятиях. Из&nbsp;частых кейсов&nbsp;&mdash; онлайн-голосование для определения лучшего изображения,
            выбора сотрудника месяца или установления наиболее популярного бонуса, предлагаемого компанией. Даже выбор
            даты и&nbsp;времени для предстоящего мероприятия можно решить путем голосования, разосланного по&nbsp;email
            или размещенного на&nbsp;сайте компании в&nbsp;виде виджета, всплывающего окна или встраивания.
            <br />
            <br />
            Вы&nbsp;можете поделиться результатами голосования с&nbsp;коллегами по&nbsp;ссылке на&nbsp;результаты или
            отображать результаты в&nbsp;реальном времени на&nbsp;большом экране.
            <br />
            <br />
            Полная кастомизация голосования позволит вам настроить дизайн в&nbsp;соответствии с&nbsp;бренд-буком
            компании, а&nbsp;раздел результатов&nbsp;&mdash; быстро сформировать отчёт по&nbsp;результатам голосования
            для руководства.
        </p>
        <Advs items={tests} title='Шаблоны онлайн-голосований' />
    </div>
);
